import scrollIntoView from 'scroll-into-view'
import $ from 'jquery'
import { ResponsiveAccordionTabs } from './../../js/libraries/foundation'


if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const faqTabs = document.querySelectorAll('[data-faq-tabs]')
  const faqAccordions = document.querySelectorAll('.FaqTabs [data-accordion]')

  if (!faqTabs.length) return

  const faqTabsOptions = {
    linkClass: 'FaqTabs-title',
    panelClass: 'FaqTabs-panel',
    allowAllClosed: true,
    multiExpand: true,
    slideSpeed: 350
  }

  faqTabs.forEach(faqTabsItem => {
    const faqTabsInit = new ResponsiveAccordionTabs($(faqTabsItem), faqTabsOptions)
  })

  $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
    // Reinit Accordions on FaqTabs component when pass from accordion to tabs style
    if ((oldSize === 'medium' || oldSize === 'small') && (newSize === 'large' || newSize === 'xlarge' || newSize === 'xxlarge')) {
      faqAccordions.forEach(accordion => {
        Foundation.reInit($(accordion))
      })
    }
  })

  $(faqTabs).on('change.zf.tabs', function() {
    faqAccordions.forEach(accordion => {
      $(accordion).foundation('up', $(accordion).find('[data-tab-content]'))
    })

    scrollIntoView(this.parentElement, {
      time: 500,
      ease: function(value){
        return 1 - Math.pow(1 - value, value / 5);
      },
      align: {
        top: 0,
        topOffset: 200
      }
    })
  })
}
