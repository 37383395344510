var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "PillPage-container" }, [
      _c("div", { staticClass: "PillPage-header" }, [
        _c("h2", { staticClass: "PillPage-title h2-style" }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "PillPage-subtitle" }, [
          _vm._v(_vm._s(_vm.subtitle))
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "PillPage-module" },
        [
          _c("PillSelect", {
            attrs: { label: _vm.selectLabel, groupedOptions: _vm.pills },
            on: { selected: _vm.handleSelection }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "PillPage-questions" },
            _vm._l(_vm.questions, function(question, index) {
              return _c(
                "div",
                { key: index, staticClass: "PillPage-question" },
                [
                  _c("h3", { staticClass: "PillPage-title h2-style" }, [
                    _vm._v(
                      "\n            " + _vm._s(question.title) + "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "PillPage-answers" },
                    _vm._l(question.answers, function(answer, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "PillPage-answer" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "PillPage-answerButton",
                              class: {
                                "PillPage-answerButton--selected":
                                  answer.isSelected
                              },
                              on: {
                                click: function($event) {
                                  return _vm.selectAnswer(question, answer)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(answer.answer) +
                                  "\n              "
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.result
            ? _c("div", { staticClass: "PillPage-result" }, [
                _c("h2", { staticClass: "PillPage-resultTitle h2-style" }, [
                  _c("span", [_vm._v(_vm._s(_vm.resultLabel))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "PillPage-resultIcon" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "189.53",
                          height: "161.92",
                          viewBox: "0 0 189.53 161.92",
                          fill: "currentColor"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M189.52,80.92a13.63,13.63,0,0,0-3.28-8.36L121.44,3.72a13.9,13.9,0,0,0-17.72-1,12.66,12.66,0,0,0,.13,17.72l45.56,48.35H12.15a12.15,12.15,0,1,0,0,24.29H149.4l-45.56,48.35a13.37,13.37,0,0,0-.13,17.72,13.73,13.73,0,0,0,17.72-1l64.85-68.92A11.71,11.71,0,0,0,189.52,80.92Z"
                          }
                        })
                      ]
                    )
                  ])
                ])
              ])
            : _vm._e()
        ],
        1
      )
    ]),
    _vm._v(" "),
    _vm.result
      ? _c("div", { staticClass: "PillPage-resultContent" }, [
          _c("h3", { staticClass: "PillPage-resultTitle h2-style" }, [
            _vm._v(_vm._s(_vm.result.title))
          ]),
          _vm._v(" "),
          _c("div", { domProps: { innerHTML: _vm._s(_vm.result.result) } })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }