import $ from 'jquery'
import { Swiper, Pagination } from 'swiper'

Swiper.use([Pagination])

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const relatedMediasSliders = document.querySelectorAll('[data-relatedMedias-slider]')

  if (!relatedMediasSliders.length) return

  const relatedMediasSliderOptions = {
    slidesPerView: 'auto',
    watchOverflow: true,
    pagination: {
      el: '.RelatedMedias-pagination',
      type: 'bullets',
      dynamicBullets: true
    },
    init: false
  }

  relatedMediasSliders.forEach(relatedMediasSlider => {
    let relatedMediasSliderInit = new Swiper(relatedMediasSlider, relatedMediasSliderOptions)

    if (Foundation.MediaQuery.upTo('medium')) {
      relatedMediasSliderInit.init()
    }

    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
      if(Foundation.MediaQuery.upTo('medium') && relatedMediasSliderInit.destroyed) {
        relatedMediasSliderInit = new Swiper(relatedMediasSlider, relatedMediasSliderOptions)
      }

      if (Foundation.MediaQuery.upTo('medium') && !relatedMediasSliderInit.initialized) {
        relatedMediasSliderInit.init()
      } else if (Foundation.MediaQuery.atLeast('large') && relatedMediasSliderInit.initialized) {
        relatedMediasSliderInit.destroy()
      }
    })
  })
}
