import scrollIntoView from 'scroll-into-view'
import ImagesLoaded from 'imagesloaded'
import $ from 'jquery'
import { Dropdown } from './../../js/libraries/foundation'


export default class Summary {
  constructor(container) {
    this.container = container
    this.content = this.container.querySelector('[data-summary-content]')
    this.anchors = this.container.querySelectorAll('[data-summary-anchorsContainer] > h2')

    this.init()
  }

  createSidebar() {
    this.container.classList.add('has-sidebar')

    const sidebar = document.createElement('aside')
    sidebar.classList.add('Summary')

    this.container.insertBefore(sidebar, this.content)

    this.createSummary(sidebar)
  }

  createMenuItem(linkText, linkHref, anchorIndex) {
    const summaryItem = document.createElement('li')
    const summaryLink = document.createElement('a')
    const summaryScroller = document.createElement('span')

    summaryScroller.classList.add('Summary-scroller')
    summaryScroller.innerText = linkText

    summaryLink.classList.add('Summary-link')
    summaryLink.setAttribute('href', `#${linkHref}`)
    summaryLink.setAttribute('data-tracking', `summary-item`)
    summaryLink.innerText = linkText
    summaryLink.id = `summary-${linkHref}`
    summaryLink.appendChild(summaryScroller)

    summaryItem.classList.add('Summary-item')
    summaryItem.setAttribute('data-index', anchorIndex)
    summaryItem.appendChild(summaryLink)

    return summaryItem
  }

  addMenuItems(anchors, container) {
    anchors.forEach((anchor, index) => {
      if (!anchor.classList.contains('ez-has-anchor')) {
        anchor.classList.add('ez-has-anchor')
        anchor.setAttribute('id', `section-${index}`)
      }

      const anchorId = anchor.getAttribute('id')
      const anchorText = anchor.innerText

      const newItem = this.createMenuItem(anchorText, anchorId, index)

      container.appendChild(newItem)
    })
  }

  createSummary(container) {
    const summaryContainer = document.createElement('div')
    const summaryButton = document.createElement('button')
    const summaryContent = document.createElement('ul')
    const summaryTitle = document.createElement('p')

    summaryTitle.classList.add('Summary-title')
    summaryTitle.innerText = 'Sommaire'

    summaryButton.classList.add('Summary-toggle')
    summaryButton.innerText = 'Sommaire'
    summaryButton.setAttribute('data-summary-dropdown', '')
    summaryButton.setAttribute('data-toggle', 'article-summary')

    summaryContent.classList.add('Summary-content', 'dropdown-pane')
    summaryContent.setAttribute('data-dropdown', '')
    summaryContent.setAttribute('id', 'article-summary')

    summaryContainer.classList.add('Summary-container')
    summaryContainer.appendChild(summaryTitle)
    summaryContainer.appendChild(summaryButton)
    summaryContainer.appendChild(summaryContent)

    container.appendChild(summaryContainer)

    this.addMenuItems(this.anchors, summaryContent)
    this.dropdownInit(summaryContent)
  }

  dropdownInit(dropdown) {
    const dropdownOptions = {
      position: 'left',
      alignment: 'bottom',
    }

    if (Foundation.MediaQuery.upTo('medium')) {
      const dropdownInit = new Dropdown($(dropdown))
    }
  }

  onMenuClick() {
    const summary = document.querySelector('.Summary')
    const summaryLinks = Array.from(summary.querySelectorAll('.Summary-link'))
    const dropdown = this.container.querySelector('.Summary-content')

    summaryLinks.forEach(summaryLink => {
      summaryLink.addEventListener('click', (e) => {
        e.preventDefault()
        let destination

        if (dropdown.classList.contains('is-open')) {
          setTimeout(() => {
            $(dropdown).foundation('close')
          }, 250)
        }

        if (e.target.hasAttribute('href')) {
          destination = e.target.getAttribute('href')
        } else {
          destination = e.target.parentNode.getAttribute('href')
        }

        scrollIntoView(document.querySelector(destination), {
          time: 750,
          ease: function(value){
            return 1 - Math.pow(1 - value, value / 5);
          },
          align: {
            top: 0
          }
        }, () => {
          window.location.replace(destination)
        })
      })
    })
  }

  scrollSpy() {
    let anchorsPositions = []
    let nextAnchorPosition
    let scrollPercent
    let scrollPx
    let windowscrollPosition
    const buttonWidth = this.container.querySelector('.Summary-link').offsetWidth

    new ImagesLoaded(this.content, () => {
      this.anchors.forEach(anchor => {
        anchorsPositions.push(anchor.offsetTop)
      })
    })

    window.addEventListener('scroll', () => {
      windowscrollPosition = window.scrollY

      anchorsPositions = []
      this.anchors.forEach(anchor => {
        anchorsPositions.push(anchor.offsetTop)
      })

      anchorsPositions.forEach((anchorPosition, index) => {
        const activeButton = document.querySelector(`.Summary-item[data-index="${index}"]`)

        if (anchorsPositions[index + 1]) {
          nextAnchorPosition = anchorsPositions[index + 1]
        } else {
          nextAnchorPosition = this.content.offsetTop + this.content.offsetHeight
        }

        if (windowscrollPosition < anchorPosition) {
          scrollPercent = 0
          scrollPx = 0
        }

        if (windowscrollPosition >= nextAnchorPosition) {
          scrollPercent = 100
          scrollPx = buttonWidth
        }

        if (anchorPosition <= windowscrollPosition && windowscrollPosition < nextAnchorPosition) {
          const anchorScrollProgress = windowscrollPosition - anchorPosition
          const distance = nextAnchorPosition - anchorPosition

          scrollPercent = (anchorScrollProgress * 100) / distance
          scrollPx = (buttonWidth * scrollPercent) / 100
        }

        activeButton.style.setProperty('--scrollPercent', `${scrollPercent}%`)
        activeButton.querySelector('.Summary-scroller').style.setProperty('clip', `rect(0 ${scrollPx}px 200px 0)`)
      })
    })
  }

  init() {
    if (!this.anchors.length) return

    this.createSidebar()
    this.onMenuClick()
    this.scrollSpy()
  }
}
