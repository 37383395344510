import scrollIntoView from 'scroll-into-view'
import { CreateTooltip } from '../../js/helpers/createTooltip'


if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const initializedTooltips = []

  createTooltips(initializedTooltips)
  definitionInPage()
  mostSearchedClick(initializedTooltips)
}

function definitionInPage() {
  const definitionTooltips = document.querySelectorAll('[data-glossary-tooltip]')

  if (!definitionTooltips.length) return

  definitionTooltips.forEach(tooltip => {
    if (tooltip.classList.contains('GlossaryTooltip--inPage')) {
      const sup = document.createElement('sup')
      sup.innerText = '[Définition]'

      tooltip.appendChild(sup)
    }
  })
}

function createTooltips(initializedTooltips) {
  const definitionTooltips = document.querySelectorAll('[data-glossary-tooltip]')

  if (!definitionTooltips.length) return

  definitionTooltips.forEach(definitionTooltip => {
    const tooltipOptions = {
      appendTo: () => document.body
    }

    const definitionTooltipInit = new CreateTooltip(definitionTooltip, tooltipOptions)

    initializedTooltips.push(definitionTooltipInit)
  })
}

function mostSearchedClick(initializedTooltips) {
  const mostSearchedWords = document.querySelectorAll('[data-glossaryMostSearched-word]')

  if (!mostSearchedWords.length) return

  mostSearchedWords.forEach(word => {
    word.addEventListener('click', (e) => {
      e.preventDefault()
      const destination = e.target.getAttribute('href')

      scrollIntoView(document.querySelector(destination), {
        time: 750,
        ease: function(value){
          return 1 - Math.pow(1 - value, value / 5);
        },
        align: {
          top: 0,
          topOffset: 200
        }
      }, () => {
        const glossaryTooltips = document.querySelectorAll('[data-glossary-tooltip]')

        glossaryTooltips.forEach((glossaryTooltip, index) => {
          if (word.getAttribute('data-id') === glossaryTooltip.getAttribute('data-id')) {
            initializedTooltips[index].definitionTooltipInit.show()
          }
        })
      })
    })
  })
}
