import $ from 'jquery'
import throttle from './../../js/helpers/throttle'
import Summary from './summarycreate'


if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const summaryContainer = document.querySelector('.article [data-summary], .landing_page [data-summary]')

  if (!summaryContainer) return

  const summaryInit = new Summary(summaryContainer)
  stickSidebar(summaryContainer)
}

function stickSidebar(summaryContainer) {
  const sidebar = summaryContainer.querySelector('.Summary')

  if (!sidebar) return

  let offset

  if (Foundation.MediaQuery.is('medium only')) {
    offset = 130
  } else {
    offset = 85
  }

  $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
    if (Foundation.MediaQuery.is('medium only')) {
      offset = 130
    } else {
      offset = 85
    }
  })

  window.addEventListener('scroll', throttle(() => {
    const richtextBounds = summaryContainer.getBoundingClientRect()

    if (richtextBounds.top <= offset) {
      if (!sidebar.classList.contains('Summary--sticky')) {
        sidebar.classList.add('Summary--sticky')
      }
    } else {
      if (sidebar.classList.contains('Summary--sticky')) {
        sidebar.classList.remove('Summary--sticky')
      }
    }
  }, 16))
}
