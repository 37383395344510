import getSiblings from './../../../js/helpers/getSiblings'


class MenuDesktop {
  constructor(menuContainer) {
    this.container = menuContainer
    this.items = menuContainer.querySelectorAll('[data-menuDesktop-item]')
    this.subitems = menuContainer.querySelectorAll('[data-menuDesktop-subitem]')
    this.closeButton = menuContainer.querySelector('[data-menuDesktop-close]')

    this.init()
  }

  initMenu() {
    const menuItems = this.items
    const menuSubitems = this.subitems

    menuItems.forEach((menuItem, index) => {
      const menuItemParent = menuItem.parentNode
      const subMenu = menuItemParent.querySelector('[data-menuDesktop-submenu]')

      menuItem.setAttribute('aria-expanded', 'false')
      menuItem.setAttribute('id', `desktopMenuMainLevel-${index + 1}`)
      menuItem.setAttribute('aria-controls', `dmMainLevel-${index + 1}`)

      subMenu.setAttribute('id', `dmMainLevel-${index + 1}`)
      subMenu.setAttribute('aria-labelledby', `desktopMenuMainLevel-${index + 1}`)
    })

    menuSubitems.forEach((menuSubitem, index) => {
      const menuSubitemParent = menuSubitem.parentNode
      const subMenu = menuSubitemParent.querySelector('[data-menuDesktop-articles]')

      menuSubitem.setAttribute('aria-expanded', 'false')
      menuSubitem.setAttribute('id', `menuTab-${index + 1}`)
      menuSubitem.setAttribute('aria-controls', `tab-${index + 1}`)

      subMenu.setAttribute('id', `tab-${index + 1}`)
      subMenu.setAttribute('aria-labelledby', `menuTab-${index + 1}`)
    })
  }

  closeMenu() {
    const closeButton = this.closeButton
    const menuItems = this.items
    const body = document.body

    closeButton.addEventListener('click', () => {
      menuItems.forEach(menuItem => {
        menuItem.setAttribute('aria-expanded', 'false')
        menuItem.parentNode.classList.remove('is-active')
        menuItem.parentNode.querySelector('[data-menuDesktop-submenu]').classList.remove('dropdown-active')
        body.classList.remove('menu-open--desktop')

        this.closeSubmenu(menuItem.parentNode)
      })
    })
  }

  openMenu() {
    const menuItems = this.items
    const body = document.body

    menuItems.forEach(menuItem => {
      const menuItemParent = menuItem.parentNode
      const subMenu = menuItemParent.querySelector('[data-menuDesktop-submenu]')

      menuItem.addEventListener('click', () => {
        const siblings = getSiblings(menuItem.parentNode)

        siblings.forEach(sibling => {
          sibling.querySelector('[data-menuDesktop-item]').setAttribute('aria-expanded', 'false')
          sibling.querySelector('[data-menuDesktop-submenu]').classList.remove('dropdown-active')
          sibling.classList.remove('is-active')

          this.closeSubmenu(sibling)
        })

        if (!menuItemParent.classList.contains('is-active')) {
          body.classList.add('menu-open--desktop')
          menuItem.setAttribute('aria-expanded', 'true')
          menuItemParent.classList.add('is-active')
          subMenu.classList.add('dropdown-active')
        } else {
          menuItem.setAttribute('aria-expanded', 'false')
          menuItemParent.classList.remove('is-active')
          subMenu.classList.remove('dropdown-active')
          body.classList.remove('menu-open--desktop')
        }
      })
    })
  }

  closeSubmenu(menuItem) {
    const subMenus = menuItem.querySelectorAll('[data-menuDesktop-articles]')
    const subItems = menuItem.querySelectorAll('[data-menuDesktop-subitem]')

    subMenus.forEach(subMenu => {
      subMenu.classList.remove('dropdown-active')
    })

    subItems.forEach(subItem => {
      subItem.setAttribute('aria-expanded', 'false')
      subItem.parentNode.classList.remove('is-active')
      subItem.parentNode.classList.remove('is-not-open')
    })
  }

  openSubmenu() {
    const menuSubitems = this.subitems

    menuSubitems.forEach(menuSubitem => {
      const menuSubitemParent = menuSubitem.parentNode
      const subMenu = menuSubitemParent.querySelector('[data-menuDesktop-articles]')

      menuSubitem.addEventListener('click', () => {
        const siblings = getSiblings(menuSubitem.parentNode)

        siblings.forEach(sibling => {
          sibling.querySelector('[data-menuDesktop-subitem]').setAttribute('aria-expanded', 'false')
          sibling.querySelector('[data-menuDesktop-articles]').classList.remove('dropdown-active')
          sibling.classList.add('is-not-open')
          sibling.classList.remove('is-active')
        })

        if (!menuSubitemParent.classList.contains('is-active')) {
          menuSubitem.setAttribute('aria-expanded', 'true')
          menuSubitemParent.classList.add('is-active')
          menuSubitemParent.classList.remove('is-not-open')
          subMenu.classList.add('dropdown-active')
        }
      })
    })
  }

  init() {
    this.initMenu()
    this.openMenu()
    this.openSubmenu()
    this.closeMenu()
  }
}


if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const menuContainer = document.querySelector('[data-menuDesktop]')

  if (!menuContainer) return

  const menuInit = new MenuDesktop(menuContainer)
}
