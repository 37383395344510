import $ from 'jquery'
import { Accordion } from './../../js/libraries/foundation'
import throttle from './../../js/helpers/throttle'

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  stickPlayer()
  createPlayerAccordion()
  playPodcast()
}

function stickPlayer() {
  const podcastPlayer = document.querySelector('.playlist_podcast audio[data-kaliop-player-mediaplayer], .podcast audio[data-kaliop-player-mediaplayer]')

  if (!podcastPlayer) return

  const podcastPlayerContainer = podcastPlayer.parentElement
  let offset
  let podcastPlayerBounds = podcastPlayerContainer.getBoundingClientRect()

  if (Foundation.MediaQuery.upTo('small')) {
    offset = 75
  } else {
    offset = 130
  }

  $(window).on('changed.zf.mediaquery', (event, newSize, oldSize) => {
    podcastPlayerBounds = podcastPlayerContainer.getBoundingClientRect()

    if (Foundation.MediaQuery.upTo('small')) {
      offset = 75
    } else {
      offset = 130
    }
  })

  window.addEventListener('scroll', throttle(() => {
    podcastPlayerBounds = podcastPlayerContainer.getBoundingClientRect()

    if (podcastPlayerBounds.top <= offset && !podcastPlayer.classList.contains('sticky')) {
      podcastPlayer.classList.add('sticky')
      podcastPlayerContainer.style.height = `${podcastPlayer.offsetHeight}px`
    } else if (podcastPlayerBounds.top > offset && podcastPlayer.classList.contains('sticky')) {
      podcastPlayer.classList.remove('sticky')
      podcastPlayerContainer.style.height = 'auto'
    }
  }), 50)
}

function createPlayerAccordion() {
  const playerAccordion = document.querySelector('[data-podcastplayer-accordion]')

  if (!playerAccordion) return

  let playerAccordionInit

  const playerAccordionOptions = {
    allowAllClosed: true,
    slideSpeed: 350
  }

  if (Foundation.MediaQuery.upTo('medium')) {
    playerAccordionInit = new Accordion($(playerAccordion), playerAccordionOptions)
  }

  $(window).on('changed.zf.mediaquery', (event, newSize, oldSize) => {
    if ((oldSize === 'large' || oldSize === 'xlarge' || oldSize === 'xxlarge') && (newSize === 'medium' || newSize === 'small')) {
      playerAccordionInit = new Accordion($(playerAccordion), playerAccordionOptions)
    } else if ((oldSize === 'medium' || oldSize === 'small') && (newSize === 'large' || newSize === 'xlarge' || newSize === 'xxlarge')) {
      playerAccordionInit.$element.foundation('_destroy')
    }
  })
}

function playPodcast() {
  const playerContainers = document.querySelectorAll('[data-podcast-player]')

  if (!playerContainers.length) return

  playerContainers.forEach(container => {
    const podcastPlayer = container.querySelector('[data-kaliop-player-mediaplayer]')
    const podcastTrigger = container.querySelector('[data-podcast-trigger]')

    if(podcastPlayer && podcastTrigger) {
      podcastTrigger.addEventListener('click', () => {
        podcastPlayer.play()
      })
    }
  })
}
