var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Question Question--unique",
      class: [_vm.responseValidated ? "Question--Validated" : ""]
    },
    [
      _vm.step.subtitle && !_vm.responseValidated
        ? _c("p", { staticClass: "Question-subtitle" }, [
            _vm._v("\n    " + _vm._s(_vm.step.subtitle) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.responseValidated
        ? _c(
            "p",
            {
              staticClass: "Question-responseStatus",
              class: _vm.responseStatus,
              attrs: { tabindex: "-1" }
            },
            [_vm._v("\n    " + _vm._s(_vm.labels[_vm.responseStatus]) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.responseValidated
        ? [
            _c(
              "div",
              { staticClass: "Question-choices" },
              _vm._l(_vm.step.responses, function(response, index) {
                return _c(
                  "div",
                  {
                    key: _vm.currentStepIndex + "-" + index,
                    staticClass: "Question-item Question-item--radio"
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedResponse,
                          expression: "selectedResponse"
                        }
                      ],
                      attrs: {
                        type: "radio",
                        id: "checkbox-" + _vm.currentStepIndex + "-" + index,
                        name: "checkbox-" + _vm.currentStepIndex + "-" + index,
                        disabled: _vm.responseValidated
                      },
                      domProps: {
                        value: response.score,
                        checked: _vm._q(_vm.selectedResponse, response.score)
                      },
                      on: {
                        change: function($event) {
                          _vm.selectedResponse = response.score
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: {
                          for: "checkbox-" + _vm.currentStepIndex + "-" + index
                        }
                      },
                      [_vm._v(_vm._s(response.label))]
                    )
                  ]
                )
              }),
              0
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.responseValidated
        ? _c("p", { staticClass: "Question-responseMessage" }, [
            _vm._v(_vm._s(_vm.step.responseMessage))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.responseValidated
        ? _c("div", { staticClass: "Question-buttons" }, [
            _c(
              "button",
              {
                staticClass: "Question-button",
                on: { click: _vm.nextQuestion }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.step.nextQuestionLabel || _vm.labels.nextQuestionLabel
                    ) +
                    "\n    "
                )
              ]
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }