<template>
  <div
    class="PillSelect"
    @click="toggleDropdown"
    @keyup.enter="toggleDropdown"
    @keyup.space="toggleDropdown"
    tabindex="0"
    aria-haspopup="listbox"
    :aria-expanded="showDropdown.toString()"
  >
    <div
      :class="
        `PillSelect-container ${
          showDropdown ? 'PillSelect-container--open' : ''
        }`
      "
      @keyup.escape.prevent="closeDropdown"
      role="button"
      :aria-label="label"
    >
      <span class="PillSelect-label">
        {{ selectedOption ? selectedOption.pillName : label }}
        <span class="PillSelect-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="145.35"
            height="94.22"
            viewBox="0 0 145.35 94.22"
            fill="currentColor"
          >
            <polygon
              points="73.46 94.22 0 21.63 21.38 0 73.2 51.23 123.73 0.13 145.35 21.5 73.46 94.22"
            />
          </svg>
        </span>
      </span>
    </div>
    <ul
      v-if="showDropdown"
      class="PillSelect-optgroupList"
      role="listbox"
      tabindex="-1"
    >
      <li
        v-for="(options, group) in groupedOptions"
        :key="group"
        class="PillSelect-optgroupItem"
        role="group"
        :aria-label="group"
      >
        <span class="PillSelect-optgroupLabel">{{ group }}</span>
        <ul class="PillSelect-optionsList">
          <li
            v-for="(option, index) in options"
            :key="option.pillName"
            class="PillSelect-option"
            @click.stop="selectOption(option)"
            @keyup.enter.stop="selectOption(option)"
            @keyup.escape="closeDropdown"
            @keyup.down="focusNextOption"
            @keyup.up="focusPreviousOption"
            tabindex="0"
            role="option"
            :aria-selected="
              selectedOption && option.pillName === selectedOption.pillName
            "
          >
            {{ option.pillName }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PillSelect",
  props: {
    label: {
      required: true,
      type: String
    },
    groupedOptions: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      selectedOption: null,
      showDropdown: false,
      focusedOptionIndex: -1
    };
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      if (this.showDropdown) {
        this.$nextTick(() => {
          this.focusFirstOption();
        });
      }
    },
    closeDropdown() {
      this.showDropdown = false;
      this.focusedOptionIndex = -1;
      this.$el.focus();
    },
    selectOption(option) {
      this.selectedOption = option;
      this.showDropdown = false;
      this.focusedOptionIndex = -1;
      this.$el.focus();
      this.$emit("selected", option);
    },
    focusFirstOption() {
      this.focusedOptionIndex = 0;
      this.focusOption();
    },
    focusNextOption() {
      if (
        this.showDropdown &&
        this.focusedOptionIndex < this.totalOptions - 1
      ) {
        this.focusedOptionIndex++;
        this.focusOption();
      }
    },
    focusPreviousOption() {
      if (this.showDropdown && this.focusedOptionIndex > 0) {
        this.focusedOptionIndex--;
        this.focusOption();
      }
    },
    focusOption() {
      this.$el
        .querySelectorAll(".PillSelect-option")
        [this.focusedOptionIndex].focus();
    }
  },
  computed: {
    totalOptions() {
      return this.$el.querySelectorAll(".PillSelect-option").length;
    }
  }
};
</script>
