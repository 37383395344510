import $ from 'jquery'
import { Swiper, Pagination } from 'swiper'

Swiper.use([Pagination])

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const relatedSliders = document.querySelectorAll('[data-related-slider]')

  if (!relatedSliders.length) return

  const relatedSliderOptions = {
    slidesPerView: 'auto',
    watchOverflow: true,
    pagination: {
      el: '.Related-pagination',
      type: 'bullets',
      dynamicBullets: true
    },
    init: false
  }

  relatedSliders.forEach(relatedSlider => {
    let relatedSliderInit = new Swiper(relatedSlider, relatedSliderOptions)

    if (Foundation.MediaQuery.upTo('medium')) {
      relatedSliderInit.init()
    }

    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
      if(Foundation.MediaQuery.upTo('medium') && relatedSliderInit.destroyed) {
        relatedSliderInit = new Swiper(relatedSlider, relatedSliderOptions)
      }

      if (Foundation.MediaQuery.upTo('medium') && !relatedSliderInit.initialized) {
        relatedSliderInit.init()
      } else if (Foundation.MediaQuery.atLeast('large') && relatedSliderInit.initialized) {
        relatedSliderInit.destroy()
      }
    })
  })
}
