import $ from 'jquery'

import { Foundation } from 'fjs/foundation.core'
import * as CoreUtils from 'fjs/foundation.core.utils'
import { Box } from 'fjs/foundation.util.box'
import { onImagesLoaded } from 'fjs/foundation.util.imageLoader'
import { Keyboard } from 'fjs/foundation.util.keyboard'
import { MediaQuery } from 'fjs/foundation.util.mediaQuery'
// import { Motion, Move } from 'fjs/foundation.util.motion'
import { Nest } from 'fjs/foundation.util.nest'
// import { Timer } from 'fjs/foundation.util.timer'
import { Touch } from 'fjs/foundation.util.touch'
import { Triggers } from 'fjs/foundation.util.triggers'
// import { Abide } from 'fjs/foundation.abide'
import { Accordion } from 'fjs/foundation.accordion'
// import { AccordionMenu } from 'fjs/foundation.accordionMenu'
// import { Drilldown } from 'fjs/foundation.drilldown'
import { Dropdown } from 'fjs/foundation.dropdown'
// import { DropdownMenu } from 'fjs/foundation.dropdownMenu'
// import { Equalizer } from 'fjs/foundation.equalizer'
// import { Interchange } from 'fjs/foundation.interchange'
// import { Magellan } from 'fjs/foundation.magellan'
// import { OffCanvas } from 'fjs/foundation.offcanvas'
// import { Orbit } from 'fjs/foundation.orbit'
// import { ResponsiveMenu } from 'fjs/foundation.responsiveMenu'
// import { ResponsiveToggle } from 'fjs/foundation.responsiveToggle'
// import { Reveal } from 'fjs/foundation.reveal'
// import { Slider } from 'fjs/foundation.slider'
// import { SmoothScroll } from 'fjs/foundation.smoothScroll'
// import { Sticky } from 'fjs/foundation.sticky'
import { Tabs } from 'fjs/foundation.tabs'
// import { Toggler } from 'fjs/foundation.toggler'
// import { Tooltip } from 'fjs/foundation.tooltip'
import { ResponsiveAccordionTabs } from 'fjs/foundation.responsiveAccordionTabs'

Foundation.addToJquery($)

// Add Foundation Utils to Foundation global namespace for backwards
// compatibility.
Foundation.rtl = CoreUtils.rtl
Foundation.GetYoDigits = CoreUtils.GetYoDigits
Foundation.transitionend = CoreUtils.transitionend
Foundation.RegExpEscape = CoreUtils.RegExpEscape
Foundation.onLoad = CoreUtils.onLoad

Foundation.Box = Box
Foundation.onImagesLoaded = onImagesLoaded
Foundation.Keyboard = Keyboard
Foundation.MediaQuery = MediaQuery
// Foundation.Motion = Motion
// Foundation.Move = Move
Foundation.Nest = Nest
// Foundation.Timer = Timer

// Touch and Triggers previously were almost purely sede effect driven,
// so no need to add it to Foundation, just init them.
Touch.init($)
Triggers.init($, Foundation)
MediaQuery._init()

// Foundation.plugin(Abide, 'Abide')
Foundation.plugin(Accordion, 'Accordion')
// Foundation.plugin(AccordionMenu, 'AccordionMenu')
// Foundation.plugin(Drilldown, 'Drilldown')
Foundation.plugin(Dropdown, 'Dropdown')
// Foundation.plugin(DropdownMenu, 'DropdownMenu')
// Foundation.plugin(Equalizer, 'Equalizer')
// Foundation.plugin(Interchange, 'Interchange')
// Foundation.plugin(Magellan, 'Magellan')
// Foundation.plugin(OffCanvas, 'OffCanvas')
// Foundation.plugin(Orbit, 'Orbit')
// Foundation.plugin(ResponsiveMenu, 'ResponsiveMenu')
// Foundation.plugin(ResponsiveToggle, 'ResponsiveToggle')
// Foundation.plugin(Reveal, 'Reveal')
// Foundation.plugin(Slider, 'Slider')
// Foundation.plugin(SmoothScroll, 'SmoothScroll')
// Foundation.plugin(Sticky, 'Sticky')
Foundation.plugin(Tabs, 'Tabs')
// Foundation.plugin(Toggler, 'Toggler')
// Foundation.plugin(Tooltip, 'Tooltip')
Foundation.plugin(ResponsiveAccordionTabs, 'ResponsiveAccordionTabs')

export {
  Foundation,
  CoreUtils,
  Box,
  onImagesLoaded,
  Keyboard,
  MediaQuery,
  // Motion,
  Nest,
  // Timer,
  Touch,
  Triggers,
  //  Abide,
  Accordion,
  // AccordionMenu,
  // Drilldown,
  Dropdown,
  // DropdownMenu,
  // Equalizer,
  // Interchange,
  // Magellan,
  // OffCanvas,
  // Orbit,
  // ResponsiveMenu,
  // ResponsiveToggle,
  // Reveal,
  // Slider,
  // SmoothScroll,
  // Sticky,
  Tabs,
  // Toggler,
  // Tooltip,
  ResponsiveAccordionTabs
}

export default Foundation
