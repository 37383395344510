<template>
  <section
    class="Quiz"
    :class="[
      `Quiz--${quiz.typeIdentifier}`,
      `Quiz--${quiz.color}`,
      currentStepIndex === null ? 'Quiz--start' : '',
      currentStepIndex !== null && currentStepIndex === (steps.length) ? 'Quiz--end' : ''
    ]"
    :data-steps-number="steps.length"
    :data-current-step="currentStepIndex"
  >

    <div class="Quiz-header" v-if="currentStepIndex === null || currentStepIndex === (steps.length)">
      <img :src="quiz.picto.url" alt="">
      <span>{{ labels.quizType }}</span>
    </div>

    <div :role="currentStepIndex !== null ? currentStepRole : null" :aria-labelledby="currentStepIndex !== null ? `quizStep-${quiz.id}-${currentStepIndex}` : null">
      <div class="Quiz-start" v-if="currentStepIndex === null">
        <h2 class="Quiz-title h2-style">{{ quiz.title }}</h2>
        <button class="Quiz-startButton" @click="nextStep(null)">{{ labels.startButtonLabel }}</button>
      </div>

      <template v-if="currentStepIndex !== null && currentStepIndex !== (steps.length)">
        <div class="Quiz-stepInfos">
          <transition name="fade" mode="out-in" v-on:after-enter="currentStepFocus">
            <p
              class="Quiz-stepIndicator"
              v-if="steps[currentStepIndex].type !== 'separator'"
              :key="`indicator-${currentStepIndex}`"
              tabindex="-1"
            >
              {{ labels.stepLabel }} <strong>{{ currentQuestionNumber }}/{{ questionsNumbers }}</strong>
            </p>
          </transition>

          <transition name="slide" mode="out-in" v-on:after-enter="currentSeparatorFocus">
            <p class="Quiz-stepTitle" :id="`quizStep-${quiz.id}-${currentStepIndex}`" :key="`title-${currentStepIndex}`" tabindex="-1">{{ steps[currentStepIndex].title }}</p>
          </transition>
        </div>

        <transition name="slide" mode="out-in">
          <component
            :is="currentStepComponent"
            :step="steps[currentStepIndex]"
            :currentStepIndex="currentStepIndex"
            :quizType="quiz.typeIdentifier"
            :labels="labels"
            :key="`question-${currentStepIndex}`"
            @next-step="nextStep"
          />
        </transition>

        <transition name="slide-delay">
          <div
            v-if="steps[currentStepIndex].type === 'separator'"
            :key="`separator-${currentStepIndex}`"
            class="Question-buttons"
          >
            <button
              class="Question-button"
              @click="nextStep(null)"
            >
              {{ steps[currentStepIndex].nextQuestionLabel || labels.nextQuestionLabel }}
            </button>
          </div>
        </transition>
      </template>

      <template v-else-if="result !== null">
        <transition name="fade" mode="out-in">
          <Result
            :result="result"
          />
        </transition>
      </template>
    </div>
  </section>
</template>

<script>
  import QuestionUnique from "../quizquestions/QuestionUnique"
  import QuestionMultiple from "../quizquestions/QuestionMultiple"
  import QuestionSlider from "../quizquestions/QuestionSlider"
  import QuestionSliderIcon from "../quizquestions/QuestionSliderIcon"
  import Result from "./Result"

  export default {
    name: "Quiz",
    components: {
      QuestionUnique,
      QuestionMultiple,
      QuestionSlider,
      QuestionSliderIcon,
      Result
    },
    props: {
      quiz: {
        required: true,
        type: Object
      },
      steps: {
        required: true,
        type: Array
      },
      labels: {
        required: false,
        type: Object,
        default: '',
      }
    },
    data() {
      return {
        currentStepIndex: null,
        responsesScore: [],
        result: null,
        currentSeparatorsNumbers: 0
      }
    },
    watch: {
      currentStepIndex: function(newValue) {
        if (newValue === (this.steps.length)) {
          this.getResult()
        } else {
          if (this.steps[newValue].type === 'separator') {
            this.currentSeparatorsNumbers++
          }
        }
      }
    },
    updated: function () {
      if (this.currentStepIndex === 0) {
        this.$el.querySelector('.Quiz-stepIndicator').focus()
      }
    },
    computed: {
      currentStepComponent() {
        switch (this.steps[this.currentStepIndex].type) {
          case 'multiple':
            return 'QuestionMultiple'
          case 'slider':
            return 'QuestionSlider'
          case 'sliderIcon':
            return 'QuestionSliderIcon'
          case 'unique':
          default:
            return 'QuestionUnique'
        }
      },
      currentStepRole() {
        if (this.currentStepIndex === this.steps.length) return null

        switch (this.steps[this.currentStepIndex].type) {
          case 'slider':
          case 'sliderIcon':
            return 'slider'
          case 'unique':
          case 'multiple':
            return 'group'
          case 'separator':
          default:
            return null
        }
      },
      questionsNumbers() {
        let questionsNumbers = 0
        this.steps.forEach(step => {
          if (step.type !== 'separator') {
            questionsNumbers++
          }
        })

        return questionsNumbers
      },
      currentQuestionNumber() {
        return (this.currentStepIndex + 1) - this.currentSeparatorsNumbers
      }
    },
    methods: {
      nextStep(score) {
        if (score !== null) {
          this.responsesScore.push(score)
        }

        if (this.currentStepIndex === null) {
          this.currentStepIndex = 0
        } else {
          this.currentStepIndex++
        }
      },
      getResult() {
        const totalScore = this.responsesScore.reduce((a, b) => a + b, 0)

        let getResultUrl = this.quiz.getResultUrl + this.quiz.id + '/' + totalScore
        if (this.quiz.typeIdentifier === 'quiz') {
          getResultUrl += '/' + this.questionsNumbers
        }

        fetch(getResultUrl)
          .then(response => response.json())
          .then(data => {
            this.result = data
          })
      },
      currentStepFocus() {
        this.$el.querySelector('.Quiz-stepIndicator').focus()
      },
      currentSeparatorFocus() {
        if (this.steps[this.currentStepIndex].type === 'separator') {
          this.$el.querySelector('.Quiz-stepTitle').focus()
        }
      }
    }
  }
</script>

<style scoped>

</style>
