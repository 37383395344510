if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  global()
  downloadLink()
  phoneLink()
  externalLink()
  mostSearchedWord()
  glossarySuggestion()
  relatedContent()
  menu()
  quiz()
  player()
  contact()
  search()
  rubricStratum()
  summary()
}

function global() {
  setInterval(() => {
    tC.event.thirtyInterval()
  }, 30000)
}

function downloadLink() {
  const downloadLinks = document.querySelectorAll('[data-tracking="button-download"]')

  downloadLinks.forEach((downloadLink) => {
    const fileName = downloadLink.querySelector('span').innerText
    downloadLink.addEventListener('click', (e) => {
      return tC.event.contentDownload(this, {'downloadedContent': fileName})
    })
  })
}

function phoneLink() {
  const phoneLinks = document.querySelectorAll('[data-tracking="button-phone"]')

  phoneLinks.forEach((phoneLink) => {
    const phoneNumber = phoneLink.querySelector('span').innerText
    phoneLink.addEventListener('click', (e) => {
      return tC.event.phoneNumberClick(this, {'serviceName': phoneNumber})
    })
  })
}

function externalLink() {
  const externalLinks = document.querySelectorAll('[data-tracking="button-external"]')

  externalLinks.forEach((externalLink) => {
    externalLink.addEventListener('click', (e) => {
      return tC.event.externalLink(this, {'clickedURL': externalLink.href})
    })
  })
}

function mostSearchedWord() {
  const mostSearchedWords = document.querySelectorAll('.homepage .MostSearched-link')

  mostSearchedWords.forEach((mostSearchedWord) => {
    mostSearchedWord.addEventListener('click', (e) => {
      return tC.event.shortcutUnder(this, {'shortcutNameUnder': mostSearchedWord.innerText})
    })
  })
}

function glossarySuggestion() {
  const mostSearchedWords = document.querySelectorAll('.glossary .MostSearched-link')

  mostSearchedWords.forEach((mostSearchedWord) => {
    mostSearchedWord.addEventListener('click', (e) => {
      return tC.event.glossarySuggestion(this, {'glossarySuggested': mostSearchedWord.innerText})
    })
  })
}

function summary() {
  const summaryItems = document.querySelectorAll('[data-tracking="summary-item"]')

  summaryItems.forEach((summaryItem) => {
    summaryItem.addEventListener('click', (e) => {
      return tC.event.clickSummary(this, {'summaryName': summaryItem.querySelector("span").innerText})
    })
  })
}

function contact() {
  const contactForm = document.querySelector('#form_contact')

  if(contactForm !== null) {
    const contactFormSubmit = contactForm.querySelector('#contact_form_submit')
    const contactFormSubject = contactForm.querySelector('#contact_form_subject')

    contactFormSubmit.addEventListener('click', (e) => {
      const selectedOption = contactFormSubject.querySelector(`option[value="${contactFormSubject.value}"]`)
      return tC.event.contactForm(this, {'topic': selectedOption.text})
    })
  }

}

function relatedContent() {
  const relatedContents = document.querySelectorAll('body:not(.homepage) .Related-article .Card, body:not(.homepage) .RelatedMedias .CardMedia')

  relatedContents.forEach((relatedContent) => {
    relatedContent.addEventListener('click', (e) => {
      const relatedContentName = relatedContent.querySelector('.Card-title, .CardMedia-title').innerText
      const relatedContentUrl = relatedContent.querySelector('a').href

      return tC.event.bottomInternalLink(this, {
        'internalLinkName': relatedContentUrl,
        'contentName': document.title,
        'linkedContentName': relatedContentName
      });
    })
  })
}

function search() {
  const searchForms = document.querySelectorAll('form[name="search_form"]')

  searchForms.forEach((searchForm) => {
    searchForm.addEventListener('submit', (e) => {
      const searchEngine  = searchForm.querySelector('input[name="search_engine"]').value
      return tC.event.searchEngine(this, {
        'usedSearchEngine': searchEngine,
        'pageName': document.title
      })
    })
  })

  const searchResults = document.querySelectorAll('[data-tracking="search-results"]')

  var url = new URL(window.location.href);
  var searchedText = url.searchParams.get("search");

  searchResults.forEach((searchResult) => {
    const results = searchResult.querySelectorAll('.Card, .CardMedia, .CardBevel, .Accordion')
    results.forEach((result) => {
      const resultName = result.querySelector('.Card-title, .CardMedia-title, .CardBevel-title, .Accordion-title').innerText
      result.addEventListener('click', (e) => {
        return tC.event.searchResultClick(this, {
          'clickedSearchResult': resultName,
          'searchedKeyword': searchedText
        })
      })
    })
  })
}

function rubricStratum() {
  const rubricStratums = document.querySelectorAll('.RubricStratums-item')

  rubricStratums.forEach((rubricStratum) => {
    const rubricStratumTitle = rubricStratum.querySelector('.RubricStratums-title').innerText
    const rubricStratumItems = rubricStratum.querySelectorAll('.Card, .CardMedia, .CardQuiz')

    rubricStratumItems.forEach((rubricStratumItem) => {
      const rubricStratumItemTitle = rubricStratumItem.querySelector('.Card-title, .CardMedia-title, .CardQuiz-title').innerText
      const rubricStratumItemUrl = rubricStratumItem.querySelector('a').href

      rubricStratumItem.addEventListener('click', (e) => {
        return tC.event.hpSectionClick(this, {
          'internalLinkName': rubricStratumItemUrl,
          'hpSectionName': rubricStratumTitle,
          'linkedContentName': rubricStratumItemTitle
        })
      })
    })
  })
}

function menu() {
  const menuItems = document.querySelectorAll('[data-tracking="menu-item"]')

  menuItems.forEach((menuItem) => {
    const menuRubricLink = menuItem.querySelector('[data-tracking="menu-link"]')
    menuRubricLink.addEventListener('click', (e) => {
      return tC.event.menuLevelOne(this, {'menuLevel1': menuRubricLink.innerText})
    })

    const menuLinkSubrubrics = menuItem.querySelectorAll('[data-tracking="menu-sublink-subrubric"]')
    menuLinkSubrubrics.forEach((menuLinkSubrubric) => {
      menuLinkSubrubric.addEventListener('click', (e) => {
        return tC.event.menuLevelTwo(this, {
          'menuLevel2': menuLinkSubrubric.innerText,
          'parentMenuLevel1': menuRubricLink.innerText
        })
      })
    })
  })
}

function quiz() {
  const quizs = document.querySelectorAll('.Quiz')
  const config = {attributes: true};

  quizs.forEach((quiz) => {
    const stepsNumber = quiz.getAttribute('data-steps-number')
    const quizTitle = quiz.querySelector('.Quiz-title').innerText

    const callback = function (mutationsList, observer) {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-current-step') {
          const currentStep = mutation.target.getAttribute('data-current-step')

          if (currentStep === "0") {
            return tC.event.quizzStart(this, {'quizzName': quizTitle, 'quizzStepsAmount': stepsNumber})
          } else if (currentStep === stepsNumber) {
            return tC.event.quizzEnd(this, {'quizzName': quizTitle, 'quizzStepsAmount': stepsNumber})
          } else {
            const stepTitle = mutation.target.querySelector('.Quiz-stepTitle').innerText
            return tC.event.quizzStepValidation(this, {
              'quizzName': quizTitle,
              'quizzStepsAmount': stepsNumber,
              'quizzStepID': currentStep,
              'quizzStepName': stepTitle
            })
          }
        }
      }
    }
    const observer = new MutationObserver(callback);
    observer.observe(quiz, config);
  })
}

function player() {
  const players = document.querySelectorAll('[data-kaliop-player]')

  if (!players.length) return

  var ATTag = new ATInternet.Tracker.Tag();

  const canPlayTrackingFunction = canPlayTracking.bind(null, ATTag)
  const playTrackingFunction = playTracking.bind(null, ATTag)
  const pauseTrackingTrackingFunction = pauseTracking.bind(null, ATTag)

  const config = {
    attributes: true,
    attributeOldValue : true
  }

  // observer
  const mediaChangeCallback = function (mutationsList, observer) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'data-current-media-index') {
        setPlayerTracking(
          mutation.target.closest('[data-kaliop-player]'),
          canPlayTrackingFunction,
          playTrackingFunction,
          pauseTrackingTrackingFunction,
          ATTag,
          mutation.oldValue
        )
      }
    }
  };

  players.forEach(player => {
    setPlayerTracking(player, canPlayTrackingFunction, playTrackingFunction, pauseTrackingTrackingFunction)

    const playerWidget = player.querySelector(".KaliopPlayer-player")
    const observer = new MutationObserver(mediaChangeCallback);
    observer.observe(playerWidget, config);
  })
}

function setPlayerTracking(
  player,
  canPlayTrackingFunction,
  playTrackingFunction,
  pauseTrackingTrackingFunction,
  ATTag = null,
  previousMediaIndex = null
) {
  const mediaTag = player.querySelector(".KaliopPlayer-mediaPlayer")

  // Remove previous media from ATTag
  if(ATTag !== null && previousMediaIndex !== null) {
    const playerData = JSON.parse(player.getAttribute('data-kaliop-player'))
    const previousMediaData = playerData.medias[previousMediaIndex]
    ATTag.richMedia.remove(previousMediaData.id)
  }

  // Remove old event
  mediaTag.removeEventListener('canplay', canPlayTrackingFunction)
  mediaTag.removeEventListener('play', playTrackingFunction)
  mediaTag.removeEventListener('pause', pauseTrackingTrackingFunction)

  // Add new one with updated data
  mediaTag.addEventListener('canplay', canPlayTrackingFunction)
  mediaTag.addEventListener('play', playTrackingFunction)
  mediaTag.addEventListener('pause', pauseTrackingTrackingFunction)
}

function canPlayTracking(ATTag, event) {
  const player = event.target.closest('[data-kaliop-player]')
  const playerWidget = player.querySelector(".KaliopPlayer-player")
  const playerData = JSON.parse(player.getAttribute('data-kaliop-player'))
  const currentMediaData = playerData.medias[playerWidget.getAttribute('data-current-media-index')]

  ATTag.richMedia.add({
    mediaType: playerData.options.mediaType !== undefined ? playerData.options.mediaType : 'video',
    playerId: currentMediaData.id,
    mediaLabel: currentMediaData.title,
    refreshDuration: {
      0: 5,
      1: 15,
      5: 30,
      10: 60
    },
    duration: currentMediaData['duration_in_second'],
    isEmbedded: false,
    webdomain: ''
  })
}

function playTracking(ATTag, event) {
  const player = event.target.closest('[data-kaliop-player]')
  const playerWidget = player.querySelector(".KaliopPlayer-player")
  const playerData = JSON.parse(player.getAttribute('data-kaliop-player'))
  const currentMediaData = playerData.medias[playerWidget.getAttribute('data-current-media-index')]

  ATTag.richMedia.send({
    action: 'play',
    playerId: currentMediaData.id,
    mediaLabel: currentMediaData.title
  })
}

function pauseTracking(ATTag, event) {
  const player = event.target.closest('[data-kaliop-player]')
  const playerWidget = player.querySelector(".KaliopPlayer-player")
  const playerData = JSON.parse(player.getAttribute('data-kaliop-player'))
  const currentMediaData = playerData.medias[playerWidget.getAttribute('data-current-media-index')]

  ATTag.richMedia.send({
    action: 'pause',
    playerId: currentMediaData.id,
    mediaLabel: currentMediaData.title
  })
}
