import $ from 'jquery'
import { Swiper } from 'swiper'
import { Tabs } from './../../js/libraries/foundation'

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  tabs()
  tabsTitlesSlider()
}

function tabs() {
  const tabsBlocs = document.querySelectorAll('[data-tabs-bloc]')

  if (!tabsBlocs.length) return

  const tabsOptions = {
    linkClass: 'Tabs-title',
    linkActiveClass: 'Tabs-title--active',
    panelClass: 'Tabs-panel',
    panelActiveClass: 'Tabs-panel--active'
  }

  tabsBlocs.forEach(tabsBloc => {
    const tabsInit = new Tabs($(tabsBloc), tabsOptions)
  })
}

function tabsTitlesSlider() {
  const titlesSliders = document.querySelectorAll('[data-tabs-titles]')

  if (!titlesSliders.length) return

  const titlesSliderOptions = {
    slidesPerView: 'auto',
    watchOverflow: true,
    roundLengths: true,
    freeMode: true,
    freeModeMomentumVelocityRatio: 1
  }

  titlesSliders.forEach(slider => {
    const titlesSlidersInit = new Swiper(slider, titlesSliderOptions)
  })
}
