import ImagesLoaded from 'imagesloaded'
import { Swiper, Navigation, Pagination, A11y } from 'swiper'

Swiper.use([Navigation, Pagination, A11y])

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const carousels = document.querySelectorAll('[data-imagesCarousel]')

  if (!carousels.length) return

  carousels.forEach((carousel, index) => {
    const contentId = carousel.dataset.contentid || `carousel-${index}`

    const nextButton = carousel.querySelector('.ImagesCarousel-button--next')
    const prevButton = carousel.querySelector('.ImagesCarousel-button--prev')
    const pagination = carousel.querySelector('.ImagesCarousel-pagination')
    const container = carousel.querySelector('.ImagesCarousel-container')

    nextButton.setAttribute('id', `next-${contentId}`)
    prevButton.setAttribute('id', `prev-${contentId}`)
    pagination.setAttribute('id', `pagination-${contentId}`)

    const carouselsOptions = {
      slidesPerView: 'auto',
      spaceBetween: 20,
      roundLengths: true,
      watchSlidesVisibility: true,
      navigation: {
        nextEl: `#next-${contentId}`,
        prevEl: `#prev-${contentId}`,
      },
      pagination: {
        el: `#pagination-${contentId}`,
        type: 'bullets',
        dynamicBullets: true,
      },
      a11y: {
        prevSlideMessage: 'Visuel précédent',
        nextSlideMessage: 'Visuel suivant',
      },
      breakpoints: {
        640: {
          spaceBetween: 30,
        },
      },
      on: {
        init: function () {
          this.slides.forEach(slide => {
            slide.setAttribute(
              'aria-hidden',
              slide.classList.contains('swiper-slide-visible') ? 'false' : 'true'
            )
          })
        },
      },
    }

    new ImagesLoaded(carousel, () => {
      const carouselInit = new Swiper(container, carouselsOptions)

      carouselInit.on('transitionEnd', function () {
        this.slides.forEach(slide => {
          slide.setAttribute(
            'aria-hidden',
            slide.classList.contains('swiper-slide-visible') ? 'false' : 'true'
          )
        })

        if (this.slides[this.activeIndex]) {
          this.slides[this.activeIndex].focus()
        }
      })
    })
  })
}
