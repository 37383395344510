<template>
  <div class="Question Question--slider" :class="[ responseValidated ? 'Question--Validated' : '' ]">
    <p
      class="Question-subtitle"
      v-if="step.subtitle && !responseValidated"
    >
      {{ step.subtitle }}
    </p>

    <p
      v-if="responseValidated"
      class="Question-responseStatus"
      :class="responseStatus"
      tabindex="-1"
    >
      {{ labels[responseStatus] }}
    </p>

    <vue-slider
      v-if="!responseValidated"
      v-model="selectedResponseId"
      v-bind="sliderOptions"
      class="Question-slider"
    />

    <div aria-live="polite">
      <p
        v-if="!responseValidated"
        class="Question-responseLabel"
        v-html="step.text"
      ></p>
    </div>

    <p class="Question-responseMessage" v-if="responseValidated">{{ step.responseMessage }}</p>

    <div class="Question-buttons">
      <button
        v-if="!isNextQuestion"
        class="Question-button"
        @click="validateResponse"
      >
        {{ step.validateResponseLabel || labels.validateResponseLabel }}
      </button>

      <button
        v-else
        class="Question-button"
        @click="nextQuestion"
      >
        {{ step.nextQuestionLabel || labels.nextQuestionLabel }}
      </button>
    </div>
  </div>
</template>

<script>
  import VueSlider from 'vue-slider-component'
  import { GOOD_ANSWER, BAD_ANSWER, TEST_TYPE } from './consts'

  export default {
    name: 'QuestionSlider',
    props: {
      step: {
        required: true,
        type: Object
      },
      currentStepIndex: {
        required: true,
        type: Number
      },
      labels: {
        required: true,
        type: Object
      },
      quizType: {
        required: true,
        type: String
      }
    },
    components: {
      VueSlider
    },
    data () {
      return {
        isNextQuestion: false,
        selectedResponseId: null,
        responseStatus: null,
      }
    },
    computed: {
      sliderOptions: function() {
        return {
          data: this.step.responses,
          'data-value': 'id',
          'data-label': 'label',
          dotSize: 25,
          process: false,
          marks: [],
          tooltip: 'always',
          'dot-attrs': {
            'aria-valuemax': this.step.responses.length
          }
        }
      },
      selectedResponse: function() {
        let selectedResponse = {}
        this.step.responses.forEach(response => {
          if (response.id === this.selectedResponseId) {
            selectedResponse = response
          }
        })

        return selectedResponse
      },
      responseValidated: function() {
        return this.responseStatus !== null
      }
    },
    watch: {
      step:
      function(newVal) {
        this.isNextQuestion = this.quizType === TEST_TYPE
        this.selectedResponseId = newVal.responses[0].id
        this.responseStatus = null
        this.sliderOptions.data = newVal.responses
      }
    },
    updated: function () {
      if (this.responseValidated) {
        this.$el.querySelector('.Question-responseStatus').focus()
      }
    },
    methods: {
      validateResponse() {
        if (this.selectedResponse.score > 0) {
          this.responseStatus = GOOD_ANSWER
        } else {
          this.responseStatus = BAD_ANSWER
        }

        this.isNextQuestion = true
      },
      nextQuestion() {
        if (this.quizType === TEST_TYPE) {
          this.$emit('next-step', parseInt(this.selectedResponse.score))
        } else {
          this.$emit('next-step', this.responseStatus === GOOD_ANSWER ? 1 : 0)
        }
      }
    },
    mounted() {
      this.isNextQuestion = this.quizType === TEST_TYPE

      // take middle score for slider initial value
      let responsesMiddleIndex = Math.floor((this.step.responses.length - 1) / 2)
      this.selectedResponseId = this.step.responses[responsesMiddleIndex].id
    }
  }
</script>
<style scoped>

</style>
