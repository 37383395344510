var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "PillSelect",
      attrs: {
        tabindex: "0",
        "aria-haspopup": "listbox",
        "aria-expanded": _vm.showDropdown.toString()
      },
      on: {
        click: _vm.toggleDropdown,
        keyup: [
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.toggleDropdown($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            ) {
              return null
            }
            return _vm.toggleDropdown($event)
          }
        ]
      }
    },
    [
      _c(
        "div",
        {
          class:
            "PillSelect-container " +
            (_vm.showDropdown ? "PillSelect-container--open" : ""),
          attrs: { role: "button", "aria-label": _vm.label },
          on: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k(
                  $event.keyCode,
                  "escape",
                  undefined,
                  $event.key,
                  undefined
                )
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.closeDropdown($event)
            }
          }
        },
        [
          _c("span", { staticClass: "PillSelect-label" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.selectedOption ? _vm.selectedOption.pillName : _vm.label
                ) +
                "\n      "
            ),
            _c("span", { staticClass: "PillSelect-icon" }, [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "145.35",
                    height: "94.22",
                    viewBox: "0 0 145.35 94.22",
                    fill: "currentColor"
                  }
                },
                [
                  _c("polygon", {
                    attrs: {
                      points:
                        "73.46 94.22 0 21.63 21.38 0 73.2 51.23 123.73 0.13 145.35 21.5 73.46 94.22"
                    }
                  })
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.showDropdown
        ? _c(
            "ul",
            {
              staticClass: "PillSelect-optgroupList",
              attrs: { role: "listbox", tabindex: "-1" }
            },
            _vm._l(_vm.groupedOptions, function(options, group) {
              return _c(
                "li",
                {
                  key: group,
                  staticClass: "PillSelect-optgroupItem",
                  attrs: { role: "group", "aria-label": group }
                },
                [
                  _c("span", { staticClass: "PillSelect-optgroupLabel" }, [
                    _vm._v(_vm._s(group))
                  ]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "PillSelect-optionsList" },
                    _vm._l(options, function(option, index) {
                      return _c(
                        "li",
                        {
                          key: option.pillName,
                          staticClass: "PillSelect-option",
                          attrs: {
                            tabindex: "0",
                            role: "option",
                            "aria-selected":
                              _vm.selectedOption &&
                              option.pillName === _vm.selectedOption.pillName
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.selectOption(option)
                            },
                            keyup: [
                              function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                $event.stopPropagation()
                                return _vm.selectOption(option)
                              },
                              function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "escape",
                                    undefined,
                                    $event.key,
                                    undefined
                                  )
                                ) {
                                  return null
                                }
                                return _vm.closeDropdown($event)
                              },
                              function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "down",
                                    40,
                                    $event.key,
                                    ["Down", "ArrowDown"]
                                  )
                                ) {
                                  return null
                                }
                                return _vm.focusNextOption($event)
                              },
                              function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k($event.keyCode, "up", 38, $event.key, [
                                    "Up",
                                    "ArrowUp"
                                  ])
                                ) {
                                  return null
                                }
                                return _vm.focusPreviousOption($event)
                              }
                            ]
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(option.pillName) +
                              "\n        "
                          )
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }