import throttle from './../../js/helpers/throttle'


if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  stickHeader()
  headerSearchDisplay()
  headerSearchToggle()
  menuDisplay()
}

// Reduce header to hide top logos (only for home and landing pages)
function stickHeader() {
  const homeHeader = document.querySelector('[data-header-extended]')

  if (!homeHeader) return

  let scrollPos = window.pageYOffset
  let offset

  if (Foundation.MediaQuery.upTo('medium')) {
    offset = 75
  } else {
    offset = 100
  }

  if (scrollPos > offset) {
    homeHeader.classList.add('Header--sticky')
  }

  window.addEventListener('resize', throttle(() => {
    if (Foundation.MediaQuery.upTo('medium')) {
      offset = 75
    } else {
      offset = 100
    }
  }, 50))

  window.addEventListener('scroll', function() {
    scrollPos = window.pageYOffset

    if (scrollPos > offset) {
      homeHeader.classList.add('Header--sticky')
    } else {
      homeHeader.classList.remove('Header--sticky')
    }
  })
}

function headerSearchDisplay() {
  const homeHeaderSearch = document.querySelector('.homepage .Header--extended [data-header-search]')
  const homeHeroSearch = document.querySelector('.Hero--big [data-search-block]')

  if (!homeHeaderSearch || !homeHeroSearch) return

  let homeHeroSearchBottomPos = homeHeroSearch.getBoundingClientRect().bottom

  homeHeaderSearch.setAttribute('aria-hidden', 'true')

  if (homeHeroSearchBottomPos < 150) {
    homeHeaderSearch.classList.remove('Header-search--hidden')
    homeHeaderSearch.removeAttribute('aria-hidden')
  }

  window.addEventListener('scroll', throttle(function() {
    homeHeroSearchBottomPos = homeHeroSearch.getBoundingClientRect().bottom

    if (homeHeroSearchBottomPos < 150) {
      homeHeaderSearch.classList.remove('Header-search--hidden')
      homeHeaderSearch.removeAttribute('aria-hidden')
    } else {
      homeHeaderSearch.classList.add('Header-search--hidden')
      homeHeaderSearch.setAttribute('aria-hidden', 'true')
    }
  }, 100))
}

function headerSearchToggle() {
  const searchTrigger = document.querySelector('[data-search-trigger]')

  if (!searchTrigger) return

  const menuButton = document.querySelector('[data-menuMobile-trigger]')

  searchTrigger.setAttribute('aria-expanded', 'false')

  searchTrigger.addEventListener('click', () => {
    if (searchTrigger.getAttribute('aria-expanded') === 'false') {
      searchTrigger.setAttribute('aria-expanded', 'true')
    } else {
      searchTrigger.setAttribute('aria-expanded', 'false')
    }

    document.body.classList.toggle('search-open')
    document.body.classList.remove('menu-open--mobile')

    menuButton.setAttribute('aria-expanded', 'false')
  })
}

function menuDisplay() {
  const menuDesktopTrigger = document.querySelector('[data-menuDesktop-trigger]')

  if (!menuDesktopTrigger) return

  const menuDesktop = document.querySelector('[data-menuDesktop]')
  let scrollPos = 0
  let lastScrollPos = 0

  window.addEventListener('scroll', throttle(function() {
    scrollPos = window.pageYOffset

    if (scrollPos > 150 && scrollPos > lastScrollPos) {
      document.body.classList.add('menu-hidden')
      menuDesktopTrigger.setAttribute('aria-expanded', false)
    } else {
      document.body.classList.remove('menu-hidden')
      menuDesktopTrigger.setAttribute('aria-expanded', true)
    }

    lastScrollPos = scrollPos <= 0 ? 0 : scrollPos // For Mobile or negative scrolling
  }, 100))

  menuDesktopTrigger.addEventListener('click', () => {
    document.body.classList.remove('menu-hidden')

    menuDesktopTrigger.setAttribute('aria-expanded', true)
    menuDesktop.querySelector('.MenuDesktop-item:first-child .MenuDesktop-link').focus()
  })
}
