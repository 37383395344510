var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "Quiz",
      class: [
        "Quiz--" + _vm.quiz.typeIdentifier,
        "Quiz--" + _vm.quiz.color,
        _vm.currentStepIndex === null ? "Quiz--start" : "",
        _vm.currentStepIndex !== null &&
        _vm.currentStepIndex === _vm.steps.length
          ? "Quiz--end"
          : ""
      ],
      attrs: {
        "data-steps-number": _vm.steps.length,
        "data-current-step": _vm.currentStepIndex
      }
    },
    [
      _vm.currentStepIndex === null || _vm.currentStepIndex === _vm.steps.length
        ? _c("div", { staticClass: "Quiz-header" }, [
            _c("img", { attrs: { src: _vm.quiz.picto.url, alt: "" } }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.labels.quizType))])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          attrs: {
            role: _vm.currentStepIndex !== null ? _vm.currentStepRole : null,
            "aria-labelledby":
              _vm.currentStepIndex !== null
                ? "quizStep-" + _vm.quiz.id + "-" + _vm.currentStepIndex
                : null
          }
        },
        [
          _vm.currentStepIndex === null
            ? _c("div", { staticClass: "Quiz-start" }, [
                _c("h2", { staticClass: "Quiz-title h2-style" }, [
                  _vm._v(_vm._s(_vm.quiz.title))
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "Quiz-startButton",
                    on: {
                      click: function($event) {
                        return _vm.nextStep(null)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.labels.startButtonLabel))]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.currentStepIndex !== null &&
          _vm.currentStepIndex !== _vm.steps.length
            ? [
                _c(
                  "div",
                  { staticClass: "Quiz-stepInfos" },
                  [
                    _c(
                      "transition",
                      {
                        attrs: { name: "fade", mode: "out-in" },
                        on: { "after-enter": _vm.currentStepFocus }
                      },
                      [
                        _vm.steps[_vm.currentStepIndex].type !== "separator"
                          ? _c(
                              "p",
                              {
                                key: "indicator-" + _vm.currentStepIndex,
                                staticClass: "Quiz-stepIndicator",
                                attrs: { tabindex: "-1" }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.labels.stepLabel) +
                                    " "
                                ),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.currentQuestionNumber) +
                                      "/" +
                                      _vm._s(_vm.questionsNumbers)
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "transition",
                      {
                        attrs: { name: "slide", mode: "out-in" },
                        on: { "after-enter": _vm.currentSeparatorFocus }
                      },
                      [
                        _c(
                          "p",
                          {
                            key: "title-" + _vm.currentStepIndex,
                            staticClass: "Quiz-stepTitle",
                            attrs: {
                              id:
                                "quizStep-" +
                                _vm.quiz.id +
                                "-" +
                                _vm.currentStepIndex,
                              tabindex: "-1"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.steps[_vm.currentStepIndex].title)
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: "slide", mode: "out-in" } },
                  [
                    _c(_vm.currentStepComponent, {
                      key: "question-" + _vm.currentStepIndex,
                      tag: "component",
                      attrs: {
                        step: _vm.steps[_vm.currentStepIndex],
                        currentStepIndex: _vm.currentStepIndex,
                        quizType: _vm.quiz.typeIdentifier,
                        labels: _vm.labels
                      },
                      on: { "next-step": _vm.nextStep }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("transition", { attrs: { name: "slide-delay" } }, [
                  _vm.steps[_vm.currentStepIndex].type === "separator"
                    ? _c(
                        "div",
                        {
                          key: "separator-" + _vm.currentStepIndex,
                          staticClass: "Question-buttons"
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "Question-button",
                              on: {
                                click: function($event) {
                                  return _vm.nextStep(null)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.steps[_vm.currentStepIndex]
                                      .nextQuestionLabel ||
                                      _vm.labels.nextQuestionLabel
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ]
            : _vm.result !== null
            ? [
                _c(
                  "transition",
                  { attrs: { name: "fade", mode: "out-in" } },
                  [_c("Result", { attrs: { result: _vm.result } })],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }