<template>
  <div>
    <div class="PillPage-container">
      <div class="PillPage-header">
        <h2 class="PillPage-title h2-style">{{ title }}</h2>
        <p class="PillPage-subtitle">{{ subtitle }}</p>
      </div>
      <div class="PillPage-module">
        <PillSelect
          :label="selectLabel"
          :groupedOptions="pills"
          @selected="handleSelection"
        ></PillSelect>
        <div class="PillPage-questions">
          <div
            v-for="(question, index) in questions"
            :key="index"
            class="PillPage-question"
          >
            <h3 class="PillPage-title h2-style">
              {{ question.title }}
            </h3>
            <div class="PillPage-answers">
              <div
                v-for="(answer, index) in question.answers"
                :key="index"
                class="PillPage-answer"
              >
                <button
                  class="PillPage-answerButton"
                  :class="{
                    'PillPage-answerButton--selected': answer.isSelected
                  }"
                  @click="selectAnswer(question, answer)"
                >
                  {{ answer.answer }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="result" class="PillPage-result">
          <h2 class="PillPage-resultTitle h2-style">
            <span>{{ resultLabel }}</span>
            <span class="PillPage-resultIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="189.53"
                height="161.92"
                viewBox="0 0 189.53 161.92"
                fill="currentColor"
              >
                <path
                  d="M189.52,80.92a13.63,13.63,0,0,0-3.28-8.36L121.44,3.72a13.9,13.9,0,0,0-17.72-1,12.66,12.66,0,0,0,.13,17.72l45.56,48.35H12.15a12.15,12.15,0,1,0,0,24.29H149.4l-45.56,48.35a13.37,13.37,0,0,0-.13,17.72,13.73,13.73,0,0,0,17.72-1l64.85-68.92A11.71,11.71,0,0,0,189.52,80.92Z"
                />
              </svg>
            </span>
          </h2>
        </div>
      </div>
    </div>
    <div v-if="result" class="PillPage-resultContent">
      <h3 class="PillPage-resultTitle h2-style">{{ result.title }}</h3>
      <div v-html="result.result" />
    </div>
  </div>
</template>

<script>
import PillSelect from "../pillselect/PillSelect.vue";

export default {
  name: "PillModule",
  components: {
    PillSelect
  },
  props: {
    title: {
      required: true,
      type: String
    },
    subtitle: {
      required: true,
      type: String
    },
    selectLabel: {
      required: true,
      type: String
    },
    resultLabel: {
      required: true,
      type: String
    },
    pills: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    selectedPill: null,
    questions: [],
    result: null
  }),
  methods: {
    handleSelection(selectedOption) {
      this.selectedPill = selectedOption;
      this.questions = [];
      this.result = null;
      this.fetchNextQuestion(selectedOption.nextQuestion);
    },
    async fetchNextQuestion(fetchId) {
      try {
        const formData = new FormData();
        formData.append("fetchId", fetchId);

        let response = await fetch(`/pillquiz`, {
          method: "POST",
          body: formData
        });
        response = await response.json();

        if (response.type === "question") {
          const formattedAnswer = response.answers.map(answer => ({
            ...answer,
            isSelected: false
          }));
          const formattedQuestion = {
            ...response,
            answers: formattedAnswer
          };
          this.questions.push(formattedQuestion);
        } else {
          this.result = response;
        }
      } catch (error) {
        console.error("Error: ", error);
      }
    },
    selectAnswer(question, selectedAnswer) {
      const questionIndex = this.questions.findIndex(
        q => q.objectId === question.objectId
      );

      this.questions[questionIndex].answers.forEach(answer => {
        answer.isSelected = answer === selectedAnswer;
      });

      this.questions = this.questions.slice(0, questionIndex + 1);

      if (selectedAnswer.nextItemId) {
        this.result = null;
      }

      this.fetchNextQuestion(selectedAnswer.nextItemId);
    }
  }
};
</script>
