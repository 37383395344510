<template>
  <div class="Quiz-result">
    <div class="Quiz-resultInfos">
      <p class="Quiz-resultTitle">{{ result.title }}</p>
      <p class="Quiz-resultText">{{ result.text }}</p>
      <a class="Quiz-resultButton" v-if="result.knowMore" :href="result.knowMore.url">{{ result.knowMore.label }}</a>
    </div>

    <div class="Quiz-resultRelated" v-if="result.relatedContents">
      <h3 class="Quiz-resultRelatedTitle">{{ result.relatedTitle }}</h3>
      <ul class="Quiz-resultRelatedItems">
        <li class="Quiz-resultRelatedItem" v-for="(content, index) in result.relatedContents" :key="index">
          <div class="Card Card--primary Card--inline" :class="`Card--${content.color}`">
            <header class="Card-header">
              <img
                class="Card-image"
                :src="content.image.src"
                :alt="content.image.alt !== undefined ? content.image.alt : ''"
              >
            </header>
            <main class="Card-content">
              <a :href="content.link.url" class="Card-title h3-style">{{ content.title }}</a>
              <p class="Card-description">{{ content.description }}</p>
            </main>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Result",
    props: {
      result: {
        required: true,
        type: Object
      }
    }
  }
</script>

<style scoped>

</style>
