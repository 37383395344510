import tippy from 'tippy.js'

export class CreateTooltip {
  constructor(definition, customOptions) {
    this.definition = definition
    this.customOptions = customOptions
    this.definitionTooltip = null
    this.definitionTooltipInit = null
    this.closeButton = null
    this.mostSearchedWords = document.querySelectorAll('[data-glossaryMostSearched-word]')

    this.init()
  }

  insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  createDefinitionTitle(definitionName) {
    const definitionSubTitle = document.createElement('span')
    definitionSubTitle.innerText = '[Définition]'

    const definitionTitle = document.createElement('span')
    definitionTitle.classList.add('GlossaryTooltip-title')
    definitionTitle.innerText = definitionName
    definitionTitle.appendChild(definitionSubTitle)

    return definitionTitle
  }

  createDefinitionContent(definitionValue) {
    const definitionContent = document.createElement('span')
    definitionContent.classList.add('GlossaryTooltip-content')
    definitionContent.innerHTML = definitionValue

    return definitionContent
  }

  createDefinitionLink(definitionLinkTarget, definitionTitle) {
    const definionLink = document.createElement('a')
    definionLink.classList.add('GlossaryTooltip-link')
    definionLink.innerText = 'En savoir plus'
    definionLink.setAttribute('href', definitionLinkTarget)

    definionLink.addEventListener('click', (e) => {
      return tC.event.knowMore(this, {'definition':definitionTitle})
    })

    return definionLink
  }

  createTooltip() {
    const definitionValue = this.definition.getAttribute('data-content')
    const closeButtonLabel = document.createElement('span')

    closeButtonLabel.classList.add('sr-only')
    closeButtonLabel.innerText = 'Fermer la définition'

    this.closeButton = document.createElement('button')
    this.closeButton.classList.add('GlossaryTooltip-close')
    this.closeButton.setAttribute('data-close-definition', '')
    this.closeButton.appendChild(closeButtonLabel)

    this.definitionTooltip = document.createElement('div')
    this.definitionTooltip.classList.add('GlossaryTooltip-tooltip')

    this.definitionTooltip.appendChild(this.closeButton)

    if (this.definition.getAttribute('data-title') !== null) {
      this.definitionTooltip.appendChild(this.createDefinitionTitle(this.definition.getAttribute('data-title')))
    }

    this.definitionTooltip.appendChild(this.createDefinitionContent(definitionValue))

    if (this.definition.getAttribute('data-definition-link') && this.definition.getAttribute('data-definition-link') !== '') {
      this.definitionTooltip.appendChild(this.createDefinitionLink(this.definition.getAttribute('data-definition-link'), this.definition.getAttribute('data-title')))
    }
  }

  tooltipInit() {
    const tooltipOptions = {
      content: this.definitionTooltip,
      allowHTML: true,
      trigger: 'click',
      arrow: false,
      zIndex: 99,
      placement: 'bottom-start',
      interactive: true,
      aria: {
        content: 'describedby',
      },
      role: '',
      onShown(instance) {
        instance.popper.querySelector('.tippy-box').focus({preventScroll:true})
      },
      onCreate(instance) {
        instance.reference.setAttribute('aria-controls', `${instance.popper.getAttribute('id')}`)
      },
      theme: 'glossary',
      animation: 'fade',
      inertia: true,
      duration: [500, 350],
      maxWidth: 'none',
      offset: [0, 0],
      ...this.customOptions
    }

    this.definitionTooltipInit = tippy(this.definition, tooltipOptions)

    this.closeButton.addEventListener('click', () => {
      this.definitionTooltipInit.hide()
    })

    const tooltipElements = [
      this.definition,
      this.definitionTooltip.closest('.tippy-box')
    ]

    tooltipElements.forEach(element => {
      element.addEventListener('keyup', (event) => {
        if (event.key === 'Escape') {
          this.definitionTooltipInit.hide()
        }
      })
    })
  }

  init() {
    this.createTooltip()
    this.tooltipInit()
  }
}
