import $ from 'jquery'


if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const landingBlocks = document.querySelectorAll('[data-landingPage-block]')

  if (!landingBlocks.length) return

  landingBlocks.forEach(block => {
    const image = block.querySelector('[data-landingPage-blockImage]')
    const blockTitle = block.querySelector('h2')

    const imageClone = image.cloneNode(true)
    imageClone.classList.add('ezimage-field')

    if (Foundation.MediaQuery.is('medium down')) {
      blockTitle.parentNode.insertBefore(imageClone, blockTitle.nextSibling)
    }

    $(window).on('changed.zf.mediaquery', (event, newSize, oldSize) => {
      if ((oldSize === 'large' || oldSize === 'xlarge' || oldSize === 'xxlarge') && (newSize === 'medium' || newSize === 'small')) {
        blockTitle.parentNode.insertBefore(imageClone, blockTitle.nextSibling)
      } else if ((oldSize === 'medium' || oldSize === 'small') && (newSize === 'large' || newSize === 'xlarge' || newSize === 'xxlarge')) {
        imageClone.parentNode.removeChild(imageClone)
      }
    })
  })
}
