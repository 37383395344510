import $ from 'jquery'
import { Accordion } from './../../js/libraries/foundation'

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const accordions = document.querySelectorAll('[data-accordion]')

  if (!accordions.length) return

  const accordionOptions = {
    allowAllClosed: true,
    multiExpand: true,
    slideSpeed: 350
  }

  accordions.forEach(accordion => {
    const accordionInit = new Accordion($(accordion), accordionOptions)
  })
}
