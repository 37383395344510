import { CreateTooltip } from '../../js/helpers/createTooltip'


if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const contraceptivesTooltips = document.querySelectorAll('[data-contraceptive-tooltip]')

  if (!contraceptivesTooltips.length) return

  const contraceptivesTableContainer = document.querySelector('[data-contraceptives-tableContainer]')

  const tooltipOptions = {
    appendTo: contraceptivesTableContainer
  }

  contraceptivesTooltips.forEach(contraceptiveTooltip => {
    const definitionTooltipInit = new CreateTooltip(contraceptiveTooltip, tooltipOptions)
  })
}
