import scrollIntoView from 'scroll-into-view'
import throttle from './../../js/helpers/throttle'

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  smoothScroll()
  stickLetters()
}

function smoothScroll() {
  const glossaryLetters = document.querySelectorAll('[data-glossary-letter]')

  if (!glossaryLetters.length) return

  glossaryLetters.forEach(letter => {
    letter.addEventListener('click', (e) => {
      e.preventDefault()
      const destination = e.target.getAttribute('href')

      scrollIntoView(document.querySelector(destination), {
        time: 750,
        ease: function(value){
          return 1 - Math.pow(1 - value, value / 5);
        },
        align: {
          top: 0,
          topOffset: Foundation.MediaQuery.is('medium down') ? 210 : 0
        }
      }, () => {
        document
          .querySelector(`${destination} [data-glossary-tooltip]`)
          .focus({preventScroll: true})
      })
    })
  })
}

function setLettersOffset() {
  let offset

  if (Foundation.MediaQuery.upTo('small')) {
    offset = 75
  } else {
    offset = 100
  }

  return offset
}

function stickLetters() {
  const glossary = document.querySelector('[data-glossary]')

  if (!glossary) return

  const glossaryLetters = glossary.querySelector('[data-glossary-letters]')
  const offset = setLettersOffset()
  let glossaryBounds
  let pageHeight = window.innerHeight


  window.addEventListener('resize', throttle(() => {
    pageHeight = window.innerHeight

    setLettersOffset()
  }, 50))

  window.addEventListener('scroll', throttle(() => {
    glossaryBounds = glossary.getBoundingClientRect()

    if (glossaryBounds.top <= offset && !glossaryLetters.classList.contains('sticky')) {
      glossaryLetters.classList.add('sticky')
    } else if (glossaryBounds.top > offset && glossaryLetters.classList.contains('sticky')) {
      glossaryLetters.classList.remove('sticky')
    }

    if (glossaryBounds.bottom < pageHeight && !glossaryLetters.classList.contains('to-bottom')) {
      glossaryLetters.classList.add('to-bottom')

    } else if (glossaryBounds.bottom >= pageHeight && glossaryLetters.classList.contains('to-bottom')) {
      glossaryLetters.classList.remove('to-bottom')
    }
  }), 50)
}
