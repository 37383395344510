import { Swiper, Navigation, Pagination, A11y } from 'swiper'

Swiper.use([Navigation, Pagination, A11y])

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const mediasSliders = document.querySelectorAll('[data-medias-slider]')

  if (!mediasSliders.length) return

  const mediasSliderOptions = {
    slidesPerView: 'auto',
    watchOverflow: true,
    watchSlidesVisibility: true,
    navigation: {
      nextEl: '.MediasSlider-button--next',
      prevEl: '.MediasSlider-button--prev',
    },
    pagination: {
      el: '.MediasSlider-pagination',
      type: 'bullets',
      dynamicBullets: true
    },
    a11y: {
      prevSlideMessage: 'Media précédent',
      nextSlideMessage: 'Media suivant'
    },
    on: {
      init: function () {
        this.slides.forEach(slide => {
          const slideLink = slide.querySelector('a')

          if (!slide.classList.contains('swiper-slide-visible')) {
            slide.setAttribute('aria-hidden', 'true')
            slideLink.setAttribute('tabindex', '-1')
          } else {
            slide.setAttribute('aria-hidden', 'false')
          }
        })
      },
    }
  }

  mediasSliders.forEach(mediasSlider => {
    const mediasSliderInit = new Swiper(mediasSlider, mediasSliderOptions)

    mediasSliderInit.on('transitionEnd', function () {
      this.slides.forEach(slide => {
        const slideLink = slide.querySelector('a')

        if (!slide.classList.contains('swiper-slide-visible')) {
          slide.setAttribute('aria-hidden', 'true')
          slideLink.setAttribute('tabindex', '-1')
        } else {
          slide.setAttribute('aria-hidden', 'false')
          slideLink.removeAttribute('tabindex')
        }
      })

      this.slides[this.activeIndex].focus()
    })
  })
}
