<template>
  <div class="Question Question--multiple" :class="[ responsesValidated ? 'Question--Validated' : '' ]">
    <p class="Question-subtitle" v-if="!responsesValidated">{{ labels.multipleResponses }}</p>

    <p
      v-if="responsesValidated"
      class="Question-responseStatus"
      :class="responsesStatus"
      tabindex="-1"
    >
      {{ labels[responsesStatus] }}
    </p>

    <div class="Question-choices">
      <div
        v-for="(response, index) in step.responses"
        :key="`${currentStepIndex}-${index}`"
        class="Question-item Question-item--checkbox"
        :class="responseClasses(index)"
      >
        <span class="sr-only" v-if="responsesValidated">{{ expectedResponse(index) ? 'Bonne réponse' : 'Mauvaise réponse' }}</span>
        <input
          v-model="checkedResponses"
          type="checkbox"
          :id="`checkbox-${currentStepIndex}-${index}`"
          :name="`checkbox-${currentStepIndex}-${index}`"
          :value="index"
          :disabled="responsesValidated"
        />
        <label :for="`checkbox-${currentStepIndex}-${index}`">{{ response.label }}</label>
      </div>
    </div>

    <p class="Question-responseMessage" v-if="responsesValidated">{{ step.responseMessage }}</p>

    <div class="Question-buttons">
      <button
        v-if="!isNextQuestion"
        class="Question-button"
        :disabled="!checkedResponses.length"
        @click="validateResponses"
      >
        {{ step.validateResponseLabel || labels.validateResponseLabel }}
      </button>

      <button
        v-else
        class="Question-button"
        @click="nextQuestion"
        :disabled="!checkedResponses.length"
      >
        {{ step.nextQuestionLabel || labels.nextQuestionLabel }}
      </button>
    </div>
  </div>
</template>

<script>
  import { GOOD_ANSWER, BAD_ANSWER, ALMOST_ANSWER, TEST_TYPE } from './consts'

  export default {
    name: 'QuestionMultiple',
    props: {
      step: {
        required: true,
        type: Object
      },
      currentStepIndex: {
        required: true,
        type: Number
      },
      labels: {
        required: true,
        type: Object
      },
      quizType: {
        required: true,
        type: String
      }
    },
    data() {
      return {
        isNextQuestion: false,
        selectedResponses: {},
        responsesStatus: null,
        checkedResponses: [],
      }
    },
    computed: {
      responsesValidated: function() {
        return this.responsesStatus !== null
      },
      goodResponses: function() {
        let goodResponses = {}
        this.step.responses.forEach((response, index) => {
          if (response.score > 0) {
            goodResponses[index] = response
          }
        })

        return goodResponses
      }
    },
    watch: {
      step: function() {
        this.isNextQuestion = this.quizType === TEST_TYPE
        this.selectedResponses = {}
        this.responsesStatus = null
      }
    },
    updated: function () {
      if (this.responseValidated) {
        this.$el.querySelector('.Question-responseStatus').focus()
      }
    },
    methods: {
      getSelectedResponses() {
        this.checkedResponses.forEach((checkedResponseIndex) => {
          this.selectedResponses[checkedResponseIndex] = this.step.responses[checkedResponseIndex]
        })
      },
      expectedResponse(index) {
        const response = this.step.responses[index]
        const isGoodResponse = response.score > 0

        return (isGoodResponse && this.selectedResponses[index] !== undefined) || (!isGoodResponse && this.selectedResponses[index] === undefined)
      },
      validateResponses() {
        this.getSelectedResponses()

        const selectedResponsesJSON = JSON.stringify(this.selectedResponses)
        const goodResponsesJSON = JSON.stringify(this.goodResponses)
        const correctSelectedResponse = Object.keys(this.goodResponses)
          .filter(value => Object.keys(this.selectedResponses).includes(value))

        if (selectedResponsesJSON === goodResponsesJSON) {
          this.responsesStatus = GOOD_ANSWER
        } else if (correctSelectedResponse.length === 0) {
          this.responsesStatus = BAD_ANSWER
        } else {
          this.responsesStatus = ALMOST_ANSWER
        }

        this.isNextQuestion = true
      },
      nextQuestion() {
        if (!Object.keys(this.selectedResponses).length) {
          this.getSelectedResponses()
        }

        if (this.quizType === TEST_TYPE) {
          let score = 0
          Object.values(this.selectedResponses).forEach((response) => {
            score += parseInt(response.score)
          })
          this.$emit('next-step', score)
        } else {
          this.$emit('next-step', this.responsesStatus === GOOD_ANSWER ? 1 : 0)
        }
      },
      responseClasses(index) {
        if (!this.responsesStatus) return {}

        return {
          'Question-item--resultGood': this.expectedResponse(index),
          'Question-item--resultBad': !this.expectedResponse(index),
          'Question-item--responseGood': this.step.responses[index].score > 0
        }
      }
    },
    mounted() {
      this.isNextQuestion = this.quizType === TEST_TYPE
    }
  }
</script>

<style scoped>

</style>
