var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Question Question--multiple",
      class: [_vm.responsesValidated ? "Question--Validated" : ""]
    },
    [
      !_vm.responsesValidated
        ? _c("p", { staticClass: "Question-subtitle" }, [
            _vm._v(_vm._s(_vm.labels.multipleResponses))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.responsesValidated
        ? _c(
            "p",
            {
              staticClass: "Question-responseStatus",
              class: _vm.responsesStatus,
              attrs: { tabindex: "-1" }
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.labels[_vm.responsesStatus]) + "\n  "
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "Question-choices" },
        _vm._l(_vm.step.responses, function(response, index) {
          return _c(
            "div",
            {
              key: _vm.currentStepIndex + "-" + index,
              staticClass: "Question-item Question-item--checkbox",
              class: _vm.responseClasses(index)
            },
            [
              _vm.responsesValidated
                ? _c("span", { staticClass: "sr-only" }, [
                    _vm._v(
                      _vm._s(
                        _vm.expectedResponse(index)
                          ? "Bonne réponse"
                          : "Mauvaise réponse"
                      )
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.checkedResponses,
                    expression: "checkedResponses"
                  }
                ],
                attrs: {
                  type: "checkbox",
                  id: "checkbox-" + _vm.currentStepIndex + "-" + index,
                  name: "checkbox-" + _vm.currentStepIndex + "-" + index,
                  disabled: _vm.responsesValidated
                },
                domProps: {
                  value: index,
                  checked: Array.isArray(_vm.checkedResponses)
                    ? _vm._i(_vm.checkedResponses, index) > -1
                    : _vm.checkedResponses
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.checkedResponses,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = index,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.checkedResponses = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.checkedResponses = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.checkedResponses = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  attrs: {
                    for: "checkbox-" + _vm.currentStepIndex + "-" + index
                  }
                },
                [_vm._v(_vm._s(response.label))]
              )
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.responsesValidated
        ? _c("p", { staticClass: "Question-responseMessage" }, [
            _vm._v(_vm._s(_vm.step.responseMessage))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "Question-buttons" }, [
        !_vm.isNextQuestion
          ? _c(
              "button",
              {
                staticClass: "Question-button",
                attrs: { disabled: !_vm.checkedResponses.length },
                on: { click: _vm.validateResponses }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.step.validateResponseLabel ||
                        _vm.labels.validateResponseLabel
                    ) +
                    "\n    "
                )
              ]
            )
          : _c(
              "button",
              {
                staticClass: "Question-button",
                attrs: { disabled: !_vm.checkedResponses.length },
                on: { click: _vm.nextQuestion }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.step.nextQuestionLabel || _vm.labels.nextQuestionLabel
                    ) +
                    "\n    "
                )
              ]
            )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }