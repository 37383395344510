import getBrowser from './../../js/helpers/bowser'

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const contraceptivesTable = document.querySelector('[data-contraceptives-table]')

  if (!contraceptivesTable) return

  if (getBrowser() !== 'internet-explorer') {
    sortTable(contraceptivesTable)
  }
}

function sortTable(table) {
  const contraceptiveSortButtons = table.querySelectorAll('[data-contraceptives-sort]')
  const contraceptivesList = table.querySelector('[data-contraceptives-list]')
  const contraceptives = table.querySelectorAll('[data-contraceptive]')

  contraceptiveSortButtons.forEach(button => {
    let sortOrder = null
    let sortCriteria = null

    button.addEventListener('click', (e) => {
      sortCriteria = button.getAttribute('data-sort-criteria')

      contraceptiveSortButtons.forEach(button => {
        if (e.target !== button) {
          if (button.classList.contains('ContraceptivesTable-tableHeading--desc') || button.classList.contains('ContraceptivesTable-tableHeading--asc')) {
            sortOrder = null
            button.classList.remove('ContraceptivesTable-tableHeading--desc')
            button.classList.remove('ContraceptivesTable-tableHeading--asc')
          }
        }
      })

      if (sortOrder === null) {
        sortOrder = 'desc'
        button.classList.add('ContraceptivesTable-tableHeading--desc')

        reorganiseTableItems(table, sortCriteria, sortOrder)
      } else if (sortOrder === 'desc') {
        sortOrder = 'asc'
        button.classList.remove('ContraceptivesTable-tableHeading--desc')
        button.classList.add('ContraceptivesTable-tableHeading--asc')

        reorganiseTableItems(table, sortCriteria, sortOrder)
      } else if (sortOrder === 'asc') {
        sortOrder = null
        button.classList.remove('ContraceptivesTable-tableHeading--asc')

        contraceptivesList.innerHTML = ''

        contraceptives.forEach(contraceptive => {
          contraceptivesList.appendChild(contraceptive)
        })
      }
    })
  })
}

function reorganiseTableItems(table, criteria, order) {
  const list = table.querySelector('[data-contraceptives-list]')
  const entries = [...table.querySelectorAll('[data-contraceptive]')]

  const mappedEntries = entries.map((element, index) => {
    return {
      index: index,
      criteriaValue: element.children[0].getAttribute(criteria),
      element: element
    }
  })

  mappedEntries.sort((a, b) => {
    let comparison = 0

    const aValue = parseFloat(a.criteriaValue)
    const bValue = parseFloat(b.criteriaValue)

    if (aValue > bValue) {
      comparison = 1;
    }

    if (aValue < bValue) {
      comparison = -1;
    }

    return (
      (order === 'desc') ? (comparison * -1) : comparison
    )
  })

  list.innerHTML = ''

  mappedEntries.forEach(entry => {
    list.appendChild(entry.element)
  })
}
