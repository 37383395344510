var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Quiz-result" }, [
    _c("div", { staticClass: "Quiz-resultInfos" }, [
      _c("p", { staticClass: "Quiz-resultTitle" }, [
        _vm._v(_vm._s(_vm.result.title))
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "Quiz-resultText" }, [
        _vm._v(_vm._s(_vm.result.text))
      ]),
      _vm._v(" "),
      _vm.result.knowMore
        ? _c(
            "a",
            {
              staticClass: "Quiz-resultButton",
              attrs: { href: _vm.result.knowMore.url }
            },
            [_vm._v(_vm._s(_vm.result.knowMore.label))]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.result.relatedContents
      ? _c("div", { staticClass: "Quiz-resultRelated" }, [
          _c("h3", { staticClass: "Quiz-resultRelatedTitle" }, [
            _vm._v(_vm._s(_vm.result.relatedTitle))
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "Quiz-resultRelatedItems" },
            _vm._l(_vm.result.relatedContents, function(content, index) {
              return _c(
                "li",
                { key: index, staticClass: "Quiz-resultRelatedItem" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "Card Card--primary Card--inline",
                      class: "Card--" + content.color
                    },
                    [
                      _c("header", { staticClass: "Card-header" }, [
                        _c("img", {
                          staticClass: "Card-image",
                          attrs: {
                            src: content.image.src,
                            alt:
                              content.image.alt !== undefined
                                ? content.image.alt
                                : ""
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("main", { staticClass: "Card-content" }, [
                        _c(
                          "a",
                          {
                            staticClass: "Card-title h3-style",
                            attrs: { href: content.link.url }
                          },
                          [_vm._v(_vm._s(content.title))]
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "Card-description" }, [
                          _vm._v(_vm._s(content.description))
                        ])
                      ])
                    ]
                  )
                ]
              )
            }),
            0
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }