import $ from 'jquery'
import { Accordion } from './../../js/libraries/foundation'
import { Swiper, Pagination } from "swiper";

Swiper.use([Pagination])

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  createPlayerAccordion()
  initRelatedVideoSlider()
}

function createPlayerAccordion() {
  const playerAccordion = document.querySelector('[data-videoplayer-accordion]')

  if (!playerAccordion) return

  let playerAccordionInit

  const playerAccordionOptions = {
    allowAllClosed: true,
    slideSpeed: 350
  }

  if (Foundation.MediaQuery.upTo('medium')) {
    playerAccordionInit = new Accordion($(playerAccordion), playerAccordionOptions)
  }

  $(window).on('changed.zf.mediaquery', (event, newSize, oldSize) => {
    if ((oldSize === 'large' || oldSize === 'xlarge' || oldSize === 'xxlarge') && (newSize === 'medium' || newSize === 'small')) {
      playerAccordionInit = new Accordion($(playerAccordion), playerAccordionOptions)
    } else if ((oldSize === 'medium' || oldSize === 'small') && (newSize === 'large' || newSize === 'xlarge' || newSize === 'xxlarge')) {
      playerAccordionInit.$element.foundation('_destroy')
    }
  })
}

function initRelatedVideoSlider() {
  const relatedVideoSlider = document.querySelector('[data-videoPlayer-relatedSlider]')

  if (!relatedVideoSlider) return

  const relatedVideoSliderOptions = {
    slidesPerView: 'auto',
    watchOverflow: true,
    pagination: {
      el: '.VideoPlayer-relatedSliderPagination',
      type: 'bullets',
      dynamicBullets: true
    },
    init: false
  }

  let relatedVideoSliderInit = new Swiper(relatedVideoSlider, relatedVideoSliderOptions)

  if (Foundation.MediaQuery.upTo('medium')) {
    relatedVideoSliderInit.init()
  }

  $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
    if(Foundation.MediaQuery.upTo('medium') && relatedVideoSliderInit.destroyed) {
      relatedVideoSliderInit = new Swiper(relatedVideoSlider, relatedVideoSliderOptions)
    }

    if (Foundation.MediaQuery.upTo('medium') && !relatedVideoSliderInit.initialized) {
      relatedVideoSliderInit.init()
    } else if (Foundation.MediaQuery.atLeast('large') && relatedVideoSliderInit.initialized) {
      relatedVideoSliderInit.destroy()
    }
  })
}
