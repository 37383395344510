import $ from 'jquery'
import { Accordion } from './../../js/libraries/foundation'

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const rubricsContainer = document.querySelector('[data-rubrics]')

  if (!rubricsContainer) return

  cloneRubricsDescription(rubricsContainer)
  accordionInit(rubricsContainer)
}

function cloneRubricsDescription(container) {
  const rubrics = container.querySelectorAll('.Rubrics-rubric')

  rubrics.forEach(rubric => {
    const rubricDescription = rubric.querySelector('.Rubrics-rubricDescription')
    const rubricContent = rubric.querySelector('.Rubrics-rubricContent')

    rubricContent.insertBefore(rubricDescription.cloneNode(true), rubricContent.firstChild)
  })
}

function accordionInit(accordionContainer) {
  let rubricsAccordionInit
  const rubricsAccordionOptions = {
    allowAllClosed: true,
    multiExpand: true,
    slideSpeed: 350
  }

  if (Foundation.MediaQuery.upTo('medium')) {
    rubricsAccordionInit = new Accordion($(accordionContainer), rubricsAccordionOptions)
  }

  $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
    if ((oldSize === 'large' || oldSize === 'xlarge' || oldSize === 'xxlarge') && (newSize === 'medium' || newSize === 'small')) {
      rubricsAccordionInit = new Accordion($(accordionContainer), rubricsAccordionOptions)
    } else if ((oldSize === 'medium' || oldSize === 'small') && (newSize === 'large' || newSize === 'xlarge' || newSize === 'xxlarge')) {
      $(accordionContainer).foundation('_destroy')
    }
  })
}
