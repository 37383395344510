// Function to limit event listener

export default function getSiblings(element) {
  // for collecting siblings
  let siblings = []
  // if no parent, return no sibling
  if(!element.parentNode) {
    return siblings
  }
  // first child of the parent node
  let sibling  = element.parentNode.firstChild

  // collecting siblings
  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== element) {
      siblings.push(sibling)
    }
    sibling = sibling.nextSibling
  }
  return siblings
}
