import { Swiper } from 'swiper'


if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  menuSlider()
}

function menuSlider() {
  const menuSlider = document.querySelector('[data-searchblock-menu]')

  if (!menuSlider) return

  const menuSliderOptions = {
    slidesPerView: 'auto',
    watchOverflow: true,
    freeMode: true,
    freeModeMomentumVelocityRatio: 1
  }

  const menuSliderInit = new Swiper(menuSlider, menuSliderOptions)
}
