<template>
  <div class="Question Question--unique" :class="[ responseValidated ? 'Question--Validated' : '' ]">
    <p
      class="Question-subtitle"
      v-if="step.subtitle && !responseValidated"
    >
      {{ step.subtitle }}
    </p>

    <p
      v-if="responseValidated"
      class="Question-responseStatus"
      :class="responseStatus"
      tabindex="-1"
    >
      {{ labels[responseStatus] }}
    </p>

    <template v-if="!responseValidated" >
      <div class="Question-choices">
        <div
          v-for="(response, index) in step.responses"
          class="Question-item Question-item--radio"
          :key="`${currentStepIndex}-${index}`"
        >
          <input
            type="radio"
            v-model="selectedResponse"
            :id="`checkbox-${currentStepIndex}-${index}`"
            :name="`checkbox-${currentStepIndex}-${index}`"
            :value="response.score"
            :disabled="responseValidated"
          />
          <label :for="`checkbox-${currentStepIndex}-${index}`">{{ response.label }}</label>
        </div>
      </div>
    </template>

    <p class="Question-responseMessage" v-if="responseValidated">{{ step.responseMessage }}</p>

    <div v-if="responseValidated" class="Question-buttons">
      <button
        class="Question-button"
        @click="nextQuestion"
      >
        {{ step.nextQuestionLabel || labels.nextQuestionLabel }}
      </button>
    </div>
  </div>
</template>

<script>
import { GOOD_ANSWER, BAD_ANSWER, TEST_TYPE } from './consts'

export default {
  name: 'QuestionUnique',
  props: {
    step: {
      required: true,
      type: Object
    },
    currentStepIndex: {
      required: true,
      type: Number
    },
    labels: {
      required: true,
      type: Object
    },
    quizType: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      isNextQuestion: false,
      selectedResponse: null,
      responseStatus: null,
    }
  },
  computed: {
    responseValidated: function() {
      return this.responseStatus !== null
    }
  },
  watch: {
    step: function() {
      this.isNextQuestion = this.quizType === TEST_TYPE
      this.selectedResponse = null
      this.responseStatus = null
    },
    selectedResponse: function (newVal) {
      if (newVal !== null) {
        this.validateResponse()

        if (this.quizType === TEST_TYPE) {
          this.nextQuestion()
        }
      }
    }
  },
  updated: function () {
    if (this.responseValidated) {
      this.$el.querySelector('.Question-responseStatus').focus()
    }
  },
  methods: {
    validateResponse() {
      if (this.selectedResponse > 0) {
        this.responseStatus = GOOD_ANSWER
      } else {
        this.responseStatus = BAD_ANSWER
      }

      this.isNextQuestion = true
    },
    nextQuestion() {
      if (this.quizType === TEST_TYPE) {
        this.$emit('next-step', parseInt(this.selectedResponse))
      } else {
        this.$emit('next-step', this.responseStatus === GOOD_ANSWER ? 1 : 0)
      }
    }
  },
  mounted() {
    this.isNextQuestion = this.quizType === TEST_TYPE
  }
}
</script>

<style scoped>

</style>
