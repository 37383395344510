// import $ from 'jquery'


if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

// Mobile menu toggle function
function init() {
  const body = document.body
  const menuButton = document.querySelector('[data-menuMobile-trigger]')
  const menuOverlay = document.querySelector('[data-menuMobile-overlay]')

  if (!menuButton || !menuOverlay) return

  const searchTrigger = document.querySelector('[data-search-trigger]')

  menuButton.addEventListener('click', () => {
    body.classList.toggle('menu-open--mobile')
    body.classList.remove('search-open')

    menuButton.getAttribute('aria-expanded') === "true" ? menuButton.setAttribute('aria-expanded', "false") : menuButton.setAttribute('aria-expanded', "true")

    searchTrigger.setAttribute('aria-expanded', 'false')
  })

  menuOverlay.addEventListener('click', () => {
    body.classList.remove('menu-open--mobile')
  })
}
