import $ from 'jquery'
import { Accordion } from './../../js/libraries/foundation'
import { Swiper, Navigation, Pagination, A11y } from 'swiper'

import getSiblings from './../../js/helpers/getSiblings'

Swiper.use([Navigation, Pagination, A11y])

class DrilldownMenu {
  constructor(menuContainer) {
    this.menuContainer = menuContainer
    this.menuWrapper = null
    this.menu = menuContainer.querySelector('[data-rubricStratums-menu]')
    this.buttons = menuContainer.querySelectorAll('[data-rubricStratums-hasSub]')
    this.backButtons = menuContainer.querySelectorAll('[data-rubricStratums-back]')
    this.menuSubs = menuContainer.querySelectorAll('[data-rubricStratums-sub]')

    this.init()
  }

  initMenu() {
    const menuContainer = this.menuContainer
    const menu = this.menu
    const menuButtons = this.buttons
    const menuSubs = this.menuSubs

    this.menuWrapper = document.createElement('div')
    this.menuWrapper.classList.add('RubricStratumsMenu-wrapper')

    menuContainer.insertBefore(this.menuWrapper, menu)
    this.menuWrapper.appendChild(menu)

    this.setHeight(menu)

    menuButtons.forEach((menuButton, index) => {
      menuButton.setAttribute('aria-expanded', 'false')
      menuButton.setAttribute('id', `drilldownMenuItem-${index + 1}`)
      menuButton.setAttribute('aria-controls', `dmItem-${index + 1}`)
    })

    menuSubs.forEach((menuSub, index) => {
      menuSub.setAttribute('id', `dmItem-${index + 1}`)
      menuSub.setAttribute('aria-labelledby', `drilldownMenuItem-${index + 1}`)
    })
  }

  setHeight(ref) {
    this.menuContainer.style.overflowY = 'scroll'
    this.menuWrapper.style.height = `${ref.offsetHeight}px`
    this.menuContainer.style.overflow = 'auto'
  }

  openSubContent() {
    const menuButtons = this.buttons
    const menuContainer = this.menuContainer
    const menuWrapper = this.menuWrapper

    menuButtons.forEach(menuButton => {
      const parent = menuButton.parentNode
      const sub = parent.querySelector('[data-rubricStratums-sub]')

      menuButton.addEventListener('click', () => {
        const siblings = getSiblings(menuButton.parentNode)

        menuButton.setAttribute('aria-expanded', 'true')

        siblings.forEach(sibling => {
          sibling.classList.add('is-hidden')
        })

        sub.classList.add('is-active')
        sub.querySelector('[data-rubricStratums-back]').focus({preventScroll:true})

        this.setHeight(sub)
      })
    })
  }

  closeSubContent() {
    const menuBackButtons = this.backButtons
    const menuContainer = this.menuContainer
    const menuWrapper = this.menuWrapper

    menuBackButtons.forEach(menuBackButton => {
      const parent = menuBackButton.parentNode
      const parentButton = parent.closest('[data-rubricStratums-item]')
      const parentBlock = parent.parentNode.closest('[data-rubricStratums-sub]') || parent.parentNode.closest('[data-rubricStratums-menu]')

      menuBackButton.addEventListener('click', () => {
        const parentButtonSiblings = getSiblings(parentButton)

        parentButtonSiblings.forEach(parentButtonSibling => {
          parentButtonSibling.classList.remove('is-hidden')
        })

        parentButton.querySelector('[data-rubricStratums-hasSub]').setAttribute('aria-expanded', 'false')
        parentButton.querySelector('[data-rubricStratums-hasSub]').focus({preventScroll:true})

        parent.classList.remove('is-active')

        this.setHeight(parentBlock)
      })
    })
  }

  closeMenu() {
    const menu = this.menu
    const menuContainer = this.menuContainer
    const menuWrapper = this.menuWrapper
    const menuSubs = this.menuSubs
    const menuButtons = this.buttons

    menuButtons.forEach(menuButton => {
      menuButton.parentNode.classList.remove('is-hidden')
    })

    menuSubs.forEach(menuSub => {
      menuSub.classList.remove('is-active')

      menuContainer.classList.remove('MenuMobile-container--articlesSelected')
      menuContainer.classList.remove('MenuMobile-container--subrubricsSelected')

      this.setHeight(menu)
    })
  }

  init() {
    this.initMenu()
    this.openSubContent()
    this.closeSubContent()
  }
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  let rubricsStratumsSlidersInitialized = []
  let rubricsStratumMenusInitialized = []

  rubricStratumsMenu(rubricsStratumMenusInitialized)
  rubricStratumsSlider(rubricsStratumsSlidersInitialized)
  rubricStratumsAccordion(rubricsStratumsSlidersInitialized, rubricsStratumMenusInitialized)
}

function rubricStratumsAccordion(sliders, menus) {
  const rubricsStratumsAccordion = document.querySelector('[data-rubricStratums-accordion]')

  if (!rubricsStratumsAccordion) return

  let rubricsStratumsAccordionInit
  const rubricsStratumsAccordionTriggers = rubricsStratumsAccordion.querySelectorAll('[data-accordion-toggle]')

  const rubricsStratumsAccordionOptions = {
    allowAllClosed: true,
    multiExpand: true,
    slideSpeed: 350
  }

  if (Foundation.MediaQuery.only('small')) {
    rubricsStratumsAccordionInit = new Accordion($(rubricsStratumsAccordion), rubricsStratumsAccordionOptions)
  }

  $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
    if ((oldSize === 'medium' || oldSize === 'large' || oldSize === 'xlarge' || oldSize === 'xxlarge') && newSize === 'small') {
      rubricsStratumsAccordionInit = new Accordion($(rubricsStratumsAccordion), rubricsStratumsAccordionOptions)
    } else if (oldSize === 'small' && (newSize === 'medium' || newSize === 'large' || newSize === 'xlarge' || newSize === 'xxlarge')) {
      $(rubricsStratumsAccordion).foundation('_destroy')
      menus.forEach(menu => {
        menu.closeMenu()
      })
    }
  })

  rubricsStratumsAccordionTriggers.forEach((accordionToggle, index) => {
    accordionToggle.addEventListener('click', (e) => {
      $(rubricsStratumsAccordion).on('down.zf.accordion', function() {
        if (sliders[index] !== null) {
          sliders[index].update()
        }
      })

      $(rubricsStratumsAccordion).on('up.zf.accordion', function() {
        if (menus[index] !== null) {
          menus[index].closeMenu()
        }
      })
    })
  })

  $(rubricsStratumsAccordion).on('down.zf.accordion', function() {
    menus.forEach(menu => {
      menu.setHeight(menu.menu)
    })
  })
}

function rubricStratumsMenu(menusArray) {
  const menusContainers = document.querySelectorAll('[data-drilldownMenu-container]')

  if (!menusContainers.length) return

  menusContainers.forEach(menuContainer => {
    const menuInit = new DrilldownMenu(menuContainer)

    menusArray.push(menuInit)
  })
}

function rubricStratumsSlider(slidersArray) {
  const rubricsStratumsSliders = document.querySelectorAll('[data-rubricStratums-slider]')

  if (!rubricsStratumsSliders.length) return

  const sliderOptions = {
    slidesPerView: 'auto',
    watchOverflow: true,
    watchSlidesVisibility: true,
    roundLengths: true,
    navigation: {
      nextEl: '.RubricStratums-sliderButton--next',
      prevEl: '.RubricStratums-sliderButton--prev',
    },
    pagination: {
      el: '.RubricStratums-sliderPagination',
      type: 'bullets',
      dynamicBullets: true
    },
    a11y: {
      prevSlideMessage: 'Élément précédent',
      nextSlideMessage: 'Élément suivant'
    },
    on: {
      init: function () {
        this.slides.forEach(slide => {
          const slideLink = slide.querySelector('a')

          if (!slide.classList.contains('swiper-slide-visible')) {
            slide.setAttribute('aria-hidden', 'true')
            slideLink.setAttribute('tabindex', '-1')
          } else {
            slide.setAttribute('aria-hidden', 'false')
          }
        })
      },
    }
  }

  rubricsStratumsSliders.forEach(slider => {
    const sliderInit = new Swiper(slider, sliderOptions)

    sliderInit.on('transitionEnd', function () {
      this.slides.forEach(slide => {
        const slideLink = slide.querySelector('a')

        if (!slide.classList.contains('swiper-slide-visible')) {
          slide.setAttribute('aria-hidden', 'true')
          slideLink.setAttribute('tabindex', '-1')
        } else {
          slide.setAttribute('aria-hidden', 'false')
          slideLink.removeAttribute('tabindex')
        }
      })

      if (this.slides[this.activeIndex]) {
        this.slides[this.activeIndex].focus({preventScroll: true})
      }
    })

    slidersArray.push(sliderInit)
  })
}
