import $ from 'jquery'
import { Swiper, Pagination } from 'swiper'

Swiper.use([Pagination])

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const stepsSliders = document.querySelectorAll('[data-steps-slider]')

  if (!stepsSliders.length) return

  const stepsSlidersOptions = {
    slidesPerView: 'auto',
    watchOverflow: true,
    pagination: {
      el: '.Steps-pagination',
      type: 'bullets',
      dynamicBullets: true
    },
    init: false
  }

  stepsSliders.forEach(stepsSlider => {
    let stepsSliderInit = new Swiper(stepsSlider, stepsSlidersOptions)

    if (Foundation.MediaQuery.upTo('medium')) {
      stepsSliderInit.init()
    }

    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
      if(Foundation.MediaQuery.upTo('medium') && stepsSliderInit.destroyed) {
        stepsSliderInit = new Swiper(stepsSlider, stepsSlidersOptions)
        updateFraction(stepsSliderInit)
      }

      if (Foundation.MediaQuery.upTo('medium') && !stepsSliderInit.initialized) {
        stepsSliderInit.init()
      } else if (Foundation.MediaQuery.atLeast('large') && stepsSliderInit.initialized) {
        stepsSliderInit.destroy()
      }
    })

    updateFraction(stepsSliderInit)
  })
}

function updateFraction(slider) {
  const fractionPagination = slider.el.querySelector('.Steps-fraction')
  const fractionPaginationCurrent = slider.el.querySelector('.Steps-fractionCurrent')
  let slidingTimer

  slider.on('sliderMove', () => {
    clearTimeout(slidingTimer)

    if (!fractionPagination.classList.contains('is-visible')) {
      fractionPagination.classList.add('is-visible')
    }

    slidingTimer = setTimeout(() => {
      fractionPagination.classList.remove('is-visible')
    }, 1000)
  })
  slider.on('transitionStart', () => {
    fractionPaginationCurrent.innerText = slider.activeIndex + 1

    if (slider.isEnd && Foundation.MediaQuery.only('small')) {
      fractionPaginationCurrent.innerText = slider.slides.length
    } else if (slider.isEnd && Foundation.MediaQuery.only('medium')) {
      fractionPaginationCurrent.innerText = slider.activeIndex + 2
    }
  })
}
