import Vue from 'vue'

import Quiz from './Quiz.vue'

Vue.config.devtools = true


let self = {}

self.init = function() {
  // Init player for each player on the current page
  document.querySelectorAll('[data-quiz]').forEach((quizContainer) => {

    // Create eventBus to centralize event transmission between component
    const eventBus = new Vue()

    const data = JSON.parse(quizContainer.getAttribute('data-quiz'))

    let propsQuiz = {
      eventBus: eventBus,
      quiz: data.quiz,
      labels: data.labels,
      steps: data.steps
    }

    new Vue({
      render: h => h(Quiz, { props: propsQuiz })
    }).$mount(quizContainer)
  })
}

export default self
