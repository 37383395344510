import Vue from "vue";

import PillModule from "./PillModule.vue";

Vue.config.devtools = true;

let self = {};

self.init = function() {
  const pillModuleContainer = document.querySelector("[data-pill-module]");

  if (!pillModuleContainer) return;

  const eventBus = new Vue();

  const data = JSON.parse(pillModuleContainer.getAttribute("data-module"));

  let propsPillModule = {
    eventBus: eventBus,
    title: data.main_title,
    subtitle: data.main_subtitle,
    selectLabel: data.select_label,
    resultLabel: data.result_label,
    pills: JSON.parse(data.pills)
  };

  new Vue({
    render: h => h(PillModule, { props: propsPillModule })
  }).$mount(pillModuleContainer);
};

export default self;
