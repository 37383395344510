var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Question Question--sliderIcon",
      class: [_vm.responseValidated ? "Question--Validated" : ""]
    },
    [
      _vm.step.subtitle && !_vm.responseValidated
        ? _c("p", { staticClass: "Question-subtitle" }, [
            _vm._v("\n    " + _vm._s(_vm.step.subtitle) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.responseValidated
        ? _c(
            "p",
            {
              staticClass: "Question-responseStatus",
              class: _vm.responseStatus,
              attrs: { tabindex: "-1" }
            },
            [_vm._v("\n    " + _vm._s(_vm.labels[_vm.responseStatus]) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.responseValidated
        ? _c(
            "vue-slider",
            _vm._b(
              {
                staticClass: "Question-slider",
                class: "Question-slider--" + _vm.selectedResponse.icon,
                model: {
                  value: _vm.selectedResponseId,
                  callback: function($$v) {
                    _vm.selectedResponseId = $$v
                  },
                  expression: "selectedResponseId"
                }
              },
              "vue-slider",
              _vm.sliderOptions,
              false
            )
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { "aria-live": "polite" } }, [
        !_vm.responseValidated
          ? _c("p", { staticClass: "Question-responseLabel" }, [
              _vm._v(_vm._s(_vm.selectedResponse.label))
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.responseValidated
        ? _c("p", { staticClass: "Question-responseMessage" }, [
            _vm._v(_vm._s(_vm.step.responseMessage))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "Question-buttons" }, [
        !_vm.isNextQuestion
          ? _c(
              "button",
              {
                staticClass: "Question-button",
                on: { click: _vm.validateResponse }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.step.validateResponseLabel ||
                        _vm.labels.validateResponseLabel
                    ) +
                    "\n    "
                )
              ]
            )
          : _c(
              "button",
              {
                staticClass: "Question-button",
                on: { click: _vm.nextQuestion }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.step.nextQuestionLabel || _vm.labels.nextQuestionLabel
                    ) +
                    "\n    "
                )
              ]
            )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }