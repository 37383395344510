// ------
// Styles
// ------
import "core-js/stable";
import "./styles/index.scss"

// ---------
// Libraries
// ---------
import $ from 'jquery'
import "what-input"
import kaliopMediaPlayerHandler from "kaliop-media-player";

// ---------
// Libraries
// ---------
import "./js/polyfills"

// -------
// Helpers
// -------
import "./js/helpers/bowser"

// -------
// Scripts
// -------
import "./components/accordion/accordion"
import "./components/contraceptive/contraceptive"
import "./components/contraceptivestable/contraceptivestable"
import "./components/faqtabs/faqtabs"
import "./components/form/form"
import "./components/glossary/glossary"
import "./components/glossarytooltip/glossarytooltip"
import "./components/header/header"
import "./components/imagescarousel/imagescarousel"
import "./components/landingpage/landingpage"
import "./components/mediasslider/mediasslider"
import "./components/menus/menudesktop/menudesktop"
import "./components/menus/menumobile/menumobile"
import "./components/menus/menumobiletrigger/menumobiletrigger"
import "./components/podcastplayer/podcastplayer"
import "./components/related/related"
import "./components/relatedmedias/relatedmedias"
import "./components/rubrics/rubrics"
import "./components/rubricstratums/rubricstratums"
import "./components/searchblock/searchblock"
import "./components/searchresults/searchresults"
import "./components/steps/steps"
import "./components/summary/summary"
import "./components/tabs/tabs"
import "./components/videoplayer/videoplayer"
import "./js/tracking"

import quizAppHandler from "./components/quiz/quizAppHandler"
import pillAppHandler from "./components/pillpage/pillAppHandler"

kaliopMediaPlayerHandler.init()
quizAppHandler.init()
pillAppHandler.init()

// Init Foundation (need jquery)
window.addEventListener('load', () => {
  $(document).foundation()
})
