if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const forms = document.querySelectorAll('[data-form]')

  if (!forms.length) return

  forms.forEach(form => {
    const allFormItems = form.querySelectorAll('input[required], select[required], textarea[required]')
    const inputs = form.querySelectorAll('input[required]')
    const textareas = form.querySelectorAll('textarea[required]')
    const selects = form.querySelectorAll('select[required]')
    const submitButton = form.querySelector('[type="submit"]')

    inputs.forEach(input => {
      const inputType = input.getAttribute('type')

      switch (inputType) {
        case 'text':
        case 'email':
          input.addEventListener('blur', () => {
            toggleError(input)
            formButtonState(allFormItems, submitButton)
          })
          break
        case 'checkbox':
        case 'radio':
          input.addEventListener('change', () => {
            toggleError(input)
            formButtonState(allFormItems, submitButton)
          })
        default:
          break
      }
    })

    textareas.forEach(textarea => {
      textarea.addEventListener('blur', () => {
        toggleError(textarea)
        formButtonState(allFormItems, submitButton)
      })
    })

    selects.forEach(select => {
      select.addEventListener('change', () => {
        toggleError(select)
        formButtonState(allFormItems, submitButton)
      })
    })
  })
}

function toggleError(formField) {
  if (!checkValidity(formField)) {
    formField.classList.add('has-error')
  } else {
    formField.classList.remove('has-error')
  }
}

function formButtonState(formElements, submitButton) {
  const formElementsArray = Array.from(formElements)

  const hasError = formElementsArray.some((item) => {
    return !checkValidity(item)
  })

  if (hasError) {
    submitButton.setAttribute('disabled', '')
  } else {
    submitButton.removeAttribute('disabled')
  }
}

function checkValidity(formElement) {
  let inputValid = true

  inputValid = inputValid && (!formElement.validity.typeMismatch && !formElement.validity.patternMismatch)

  if(formElement.type === 'checkbox') {
    inputValid = inputValid && formElement.checked
  } else {
    inputValid = inputValid && (formElement.value !== '')
  }

  return inputValid
}
