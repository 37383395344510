import Bowser from'bowser'

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const body = document.body
  const userAgentInfos = Bowser.getParser(window.navigator.userAgent)

  const browser = userAgentInfos
    .getBrowserName({ toLowerCase: true })
    .replace(/\s+/g, '-')
  const os = userAgentInfos
    .getOSName({ toLowerCase: true })
    .replace(/\s+/g, '-')

  body.classList.add(browser)
  body.classList.add(os)
}

export default function getBrowser() {
  const body = document.body
  const userAgentInfos = Bowser.getParser(window.navigator.userAgent)

  return userAgentInfos
    .getBrowserName({ toLowerCase: true })
    .replace(/\s+/g, '-')
}
